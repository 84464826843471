<template>
  <div>
    <b-modal title="도움말 및 변경사항" size="xl" v-model="v" ok-only>
      <iframe src="https://v1.embednotion.com/embed/f8b45fbe53be4498be545fe905816c93"></iframe>
    </b-modal>
  </div>
</template>
<style scoped>
iframe { width: 100%; height: calc(100vh - 230px); border: 0; border-radius: 10px; padding: 0; }
</style>
<script>

export default {
  name: 'DeliveryBoardHelp',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Boolean,
  },
  data() {
    return {

    }
  },
  computed: {
    v: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('update:value', v);
      }
    }
  },
  async created() {
  },
  methods: {
  }
}
</script>
