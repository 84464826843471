<script>
// import orderShiftModal from '@/views/order/OrderModal/OrderShiftModal.vue';
import orderTab from '@/views/order/OrderModal/OrderTab.vue';
import deliveryTab from '@/views/order/OrderModal/DeliveryTab.vue';
import qnaTab from '@/views/order/OrderModal/QnaTab.vue';
import logTab from '@/views/order/OrderModal/LogTab.vue';

export default {
  name: 'OrderModal',
  props: ['colMap', 'shopMap', 'statusCodeMap', 'delayCodeMap', 'priceMetaMap', 'items', 'handleDragover'],
  // components: {orderShiftModal, orderTab, deliveryTab, logTab, qnaTab},
  components: {orderTab, deliveryTab, logTab, qnaTab},
  data() {
    return {
      item_status_claim_set: [],
      modal: {
        detail: false,
        diff: false,
      },
      item: {},
      cki: {
        ckInvoice: false,
        abroad_invoice: false,
        domestic_invoice: false,
      },
      item_org: {},
      diffHtml: '',
      variantMap: {
        order: 'primary',
        abroad: 'warning',
        domestic: 'success',
        global: 'secondary',
        after: 'danger'
      }
    }
  },
  async created() {

  },
  computed: {
    orderedStatus() {
      return this.$C.ORDER_STATUS_CODE.filter(e => !e.code.in('order', 'payment'))
          .map(e => ({...e, _d: this.item[e.code + '_date'], _dt: this.item[e.code + '_dt']}))
          .filter(e => e._d).keySort('_d:1,_dt:1')
    }
  },
  methods: {
    showModal(row) {
      // 만약 claim_cost 가 있는데 claim_cost_item 이 없다면 임시 마이그레이션 한다. blu 의 initClaimCost 참조
      // 이후 claim_cost 수정시, 항목을 추가하면 claim_cost 가 초기화되는 현상 방지용
      if (row.item.claim_cost != null && row.item.claim_item == null) {
        row.item.claim_item = [{name: '반품비', cost: row.item.claim_cost, fixed: true}];
      }

      this.item_org = row.item;
      this.item = this.$utils.clone(row.item);
      this.modal.detail = true;

      this.adjustClaimSet(this.item);
      // this.item.customer_estimate_date = moment(row.del_plan_date).locale('ko').format('MM/DD(ddd)');
    },
    adjustClaimSet(item) {
      let set = this.$C.CLAIM_SET[item.order_status_code];
      if (set) {
        this.item_status_claim_set = set;
      } else {
        this.item_status_claim_set = [];
      }
    },
    showDiffModal(item) {
      this.$modal.show({
        title: '수정이력 확인',
        type: 'diff',
        item: item,
        colMap: this.colMap,
        db: 'balis',
        collection: 'deliver_boards'
      });
    },
    syncItem(res) {
      /** API 의 response로 온 결과값을 현재 item에 적용하여 싱크를 맞춘다 */
      res.$set && Object.entries(res.$set).forEach(([k, v]) => {
        if (Object.prototype.toString.call(v) === '[object Array]') {
          this.item[k] = this.$utils.clone(v);
          this.item_org[k] = this.$utils.clone(v);
          return;
        }
        this.item[k] = v;
        this.item_org[k] = v;
      });
      res.$unset && Object.entries(res.$unset).forEach(([k, v]) => {
        delete this.item[k];
        delete this.item_org[k];
      });
      res.$push && Object.entries(res.$push).forEach(([k, v]) => {
        if (!this.item[k]) this.item[k] = [];
        if (!this.item_org[k]) this.item_org[k] = [];
        this.item[k].push(v);
        this.item_org[k].push(v);
      });
      this.adjustClaimSet(this.item);
    },
    async godoCouponInfo() {
      let j = await this.$api.getJson(`/order/godoCouponInfo?oid=${this.item.oid}`);
      if (j) {
        if (j.coupon) {
          this.$modal.show({title: '쿠폰정보', type: 'json', item: j.coupon});
        } else if (this.item.mall_info && this.item.mall_info.coupon_name) {
          this.$modal.show({title: '쿠폰정보', type: 'json', item: {쿠폰명: this.item.mall_info.coupon_name}});
        } else {
          this.$modal.show({title: '쿠폰정보', html: '취소된 주문은 쿠폰정보를 확인할 수 없습니다'});
        }
      }
    },
    async godoSettleInfo() {
      let j = await this.$api.getJson(`/order/godoSettleInfo?oid=${this.item.oid}`);
      if (j) {
        if (j.order) {
          this.$modal.show({title: '결제로그', html: '<pre>' + j.order.settlelog + '</pre>'});
        } else {
          this.$modal.show({title: '결제로그', html: '결제로그를 확인할 수 없습니다'});
        }
      }
    },

    async setField(field, value) {
      let v = value == null ? prompt('변경할 값을 입력해주세요', this.item[field]) : value;
      if (v != null) {
        // type check를 한다.
        const colMap = this.$utils.arr2map(this.$C.DELIVER_BOARD_COLUMNS, 'code');
        if (colMap[field] && colMap[field].type === 'number') {
          if (isNaN(v)) {
            return alert(`${field} 의 값은 숫자로 입력되어야 합니다`);
          }
          v = +v;
        }
        let j = await this.$api.postJson('/order/setField', {item: {oid: this.item.oid, [field]: v}});
        if (j) {
          this.item[field] = v;
          this.item_org[field] = v;
          this.$forceUpdate();
        }
      }
    },
    async unsetField(field, force = false) {
      if (!force && !confirm(`${field} 필드를 삭제하시겠습니까?`)) return;
      let j = await this.$api.postJson('/order/unsetField', {item: {oid: this.item.oid, [field]: null}});
      if (j) {
        delete this.item[field];
        delete this.item_org[field];
        this.$forceUpdate();
      }
    },

    async modalHide(event) {
      // console.log(event, event.trigger);
      const CHECK_COL = ('receiver,receiver_phone,receiver_mobile,post_code,address,base_address,detail_address,del_memo,' +
          'abroad_del_company,abroad_invoice_no,abroad_direct,domestic_del_company,domestic_invoice_no,global_del_company,global_invoice_no,isGlobalRefund,global_refund_date,' +
          'real_packing_id,claim_memo,claim_cost,claim_item,ps_memo,pccc,pccc_name,pccc_mobile,' +
          'return_memo,return_del_company,return_invoice_no,return_shipping_del_company,return_shipping_invoice_no,' +
          'partner_claim_item,partner_claim_cost,partner_claim_modified_reason').split(',');

      // 해외배송 주문의 국내/외 운송장이 같을 경우 해외 직배송이 자동 설정되어야 한다.
      if (!this.item.abroad_direct && this.item.abroad_invoice_no && this.item.abroad_invoice_no === this.item.domestic_invoice_no) {
        this.item.abroad_direct = true;
        await this.copyDomesticToAbroad();
        alert(`해외배송정보와 국내배송정보가 동일하여 '해외 직배송'이 체크 되었습니다.`);
      }

      let modifiedCols = CHECK_COL.filter(e=> JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));
      modifiedCols.forEach(e => {
        if (typeof this.item[e] === 'string') {
          this.item[e] = this.item[e].trim();
        }
      });

      if (event.trigger === 'ok') {
        if (modifiedCols.length) {
          event.preventDefault && event.preventDefault();

          if (this.item.domestic_del_company && this.$C.DELIVERY_COMP_CODE_MAP[this.item.domestic_del_company]) {
            if (this.item.domestic_invoice_no && /-/g.test(this.item.domestic_invoice_no)) {
              return alert(`운송장 번호는 '-'를 제외한 숫자만 입력해 주세요`);
            }
          }

          if (modifiedCols.includes('domestic_del_company') && !this.item.domestic_del_company) {
            return alert(`택배사명을 입력해 주세요`);
          }
          if (modifiedCols.includes('abroad_del_company') && !this.item.abroad_del_company) {
            return alert(`택배사명을 입력해 주세요`);
          }

          if (this.item.domestic_invoice_no && !this.item.domestic_del_company) {
            return alert(`국내 택배사명을 입력해 주세요`);
          }
          if (this.item.abroad_invoice_no && !this.item.abroad_del_company) {
            return alert(`해외 택배사명을 입력해 주세요`);
          }

          if (this.item.abroad_del_company && this.$C.DELIVERY_COMP_CODE_MAP[this.item.abroad_del_company]) {
            if (this.item.abroad_invoice_no && /-/g.test(this.item.abroad_invoice_no)) {
              return alert(`운송장 번호는 '-'를 제외한 숫자만 입력해 주세요`);
            }
          }

          if ((this.item.abroad_invoice_no || this.item.domestic_invoice_no) && !this.cki.ckInvoice) {
            return alert('운송장 번호 확인 중입니다.');
          }

          if (!this.$R('ADMIN') && (this.item.abroad_invoice_no && !this.cki.abroad_invoice)) {
            return alert('운송장이 유효하지 않습니다.');
          }

          if (!this.$R('ADMIN') && (this.item.domestic_invoice_no && !this.cki.domestic_invoice)) {
            return alert('운송장이 유효하지 않습니다.');
          }

          if (modifiedCols.filter(e=>e.startsWith('return_')).length) {
            event && event.preventDefault && event.preventDefault();
            if (this.item.return_invoice_no && !this.item.return_del_company) {
              return alert(`반품 배송정보를 입력하실때는 반품 배송사를 필수로 입력해주세요`);
            }
            if (this.item.return_shipping_invoice_no && !this.item.return_shipping_del_company) {
              return alert(`반품 출고 배송정보를 입력하실때는 반품 출고 배송사를 필수로 입력해주세요`);
            }
          }

          if ((this.item.abroad_del_company && this.item.abroad_del_company.length > 40) ||
              (this.item.abroad_invoice_no && this.item.abroad_invoice_no.length > 40) ||
              (this.item.domestic_del_company && this.item.domestic_del_company.length > 40) ||
              (this.item.domestic_invoice_no && this.item.domestic_invoice_no.length > 40) ||
              (this.item.global_del_company && this.item.global_del_company.length > 40) ||
              (this.item.global_invoice_no && this.item.global_invoice_no.length > 40) ||
              (this.item.return_del_company && this.item.return_del_company.length > 40) ||
              (this.item.return_invoice_no && this.item.return_invoice_no.length > 40)) {
            return alert(`택배사 및 운송장 번호가 40자가 초과하였습니다. 40자 이내로 등록해주세요.`);
          }

          if (modifiedCols.some(e => ['claim_cost', 'claim_item', 'partner_claim_cost', 'partner_claim_item', 'partner_claim_modified_reason'].includes(e)) && !this.$R('CLAIM')) {
            return alert('CLAIM 비용 수정 권한이 없습니다');
          }

          if (this.item.claim_cost && (isNaN(this.item.claim_cost) || this.item.claim_cost < 0)) {
            return alert(`클레임 비용은 0 이상의 숫자로 입력해주세요`);
          }

          if (modifiedCols.includes('pccc') && this.item.pccc) {
            this.item.pccc = this.item.pccc.trim();
            if (this.item.pccc.length !== 13) {
              return alert(`개인통관고유부호는 13자리로 입력해주세요`);
            }
          }

          if (modifiedCols.includes('claim_item')) {
            if (this.item.claim_item.some(e => e.name === '')) {
              return alert('고객 클레임비용중 항목명이 비어있는 항목이 있습니다.');
            }
            if (this.item.claim_item.some(e => isNaN(e.cost))) {
              return alert('고객 클레임비용중 숫자가 아닌 항목이 있습니다.');
            }
          }

          if (modifiedCols.includes('partner_claim_item')) {
            if (this.item.partner_claim_item.some(e => e.name === '')) {
              return alert('파트너 정산비용중 항목명이 비어있는 항목이 있습니다.');
            }
            if (this.item.partner_claim_item.some(e => isNaN(e.cost))) {
              return alert('파트너 정산비용중 숫자가 아닌 항목이 있습니다.');
            }
          }

          if (modifiedCols.includes('base_address') || modifiedCols.includes('detail_address')) {
            modifiedCols.push('address');
            modifiedCols.push('i18n');
            this.item.address = `${this.item.base_address} ${this.item.detail_address}`.trim();
          }

          // 배송지정보(수령인, 주소, 개인통관부호, 배송기재사항 등) 변경 시 주문상세번호를 기준으로 수정사항을 반영한다.
          // 주소 관련 정보 1개라도 수정 시 address가 수정된다.
          if ((modifiedCols.includes('address') || modifiedCols.includes('del_memo') ||
              modifiedCols.includes('receiver') || modifiedCols.includes('receiver_phone') || modifiedCols.includes('receiver_mobile') ||
              modifiedCols.includes('pccc') || modifiedCols.includes('pccc_name') || modifiedCols.includes('pccc_mobile'))) {
            if (+this.item.order_detailno > +process.env.VUE_APP_ADDRESS_ITEM_ORDER_DETAILNO) {
              if (this.item.order_status_code !== 'order_receive' && !confirm('변경사항이 고객화면에도 반영됩니다. 진행하시겠습니까?')) return;
            } else {
              return alert('해당 주문의 배송지 변경이 불가능합니다.');
            }
          }

          let item = {oid: this.item.oid};
          modifiedCols.forEach(e=>item[e] = this.item[e]);
          let j = await this.$api.postJson('/order/updateDeliverBoard', {item});
          if (j) {
            this.syncItem(j);
            this.modal.detail = false;
          }
        }
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        if (modifiedCols.length) {
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            this.modal.diff = true;
            this.diffHtml = modifiedCols.map(e => `<span class="badge badge-light">${this.colMap[e] || e}</span> ${this.item_org[e] === undefined ? '값 없음' : `'${this.item_org[e]}'`} → '${this.item[e]}'`).join('<br/>');
            event.preventDefault && event.preventDefault();
            // if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
            //   event.preventDefault();
            // }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        } else { // 변경사항이 없으면
          // pass
        }
      }
    },
    async removeOrder() {
      if (confirm('정말로 해당 주문을 삭제하시겠습니까? 발란몰 등 원본이 남아있을 경우 재수집 될 수 있습니다.')) {
        let j = await this.$api.postJson('/order/removeOrder', {item: {oid: this.item.oid}});
        if (j) {
          this.items.list.splice(this.items.list.indexOf(this.item_org), 1);
          this.modal.detail = false;
        }
      }
    },
    hideModal() {
      this.modal.detail = false;
    },

    copyText(e) {
      let res = this.$utils.copyToClipboard(e);
      if (res) this.$alertTop(`"${e}" 로 복사되었습니다`);
    },

    async copyDomesticToAbroad() {
      'invoice_no,del_company,custom'.split(',').map(e => { // 배송 정보
        if (this.item.abroad_direct) {
          this.item[`domestic_${e}`] = this.item[`abroad_${e}`];
        } else {
          this.item[`domestic_${e}`] = this.item_org[`domestic_${e}`] || '';
        }
      });
      await this.$refs.deliveryTab.setDomesticComp(this.item.domestic_del_company);
    },
    syncItemOrg(k) {
      console.log('syncItemOrg', k);
      console.log(this.item[k]);
      this.item_org[k] = this.item[k];
    }
  }
}
</script>

<style scoped>
img.goods-img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.btn-disabled-opacity-1 button.btn:disabled {
  opacity: 1;
  padding: 4px;
}
</style>

<template>
  <div>
    <b-modal id="orderModal" title="주문상세" size="xl" v-model="modal.detail" ok-title="저장" cancel-title="닫기"
             @hide="modalHide">
      <template v-slot:modal-title>
        <b-badge variant="primary">{{ item.mall }}</b-badge>
        <b-badge variant="light">{{ item.channel }}</b-badge>
        <b-badge class="ml-1 pointer" variant="success" @click="copyText(item.orderno)">주문번호<i
            class="fa fa-copy ml-1"></i></b-badge>
        <span class="ml-1">
          <a v-if="item.mall === '고도몰'" :href="`${ADMIN_URL}/#/orders/list?ordno=`+item.orderno"
             target="_blank">{{ item.orderno }}</a>
          <span v-else>{{ item.orderno }}</span>
        </span>
        <b-badge class="ml-1 pointer" variant="success" @click="copyText(item.order_detailno)">주문상세번호<i
            class="fa fa-copy ml-1"></i></b-badge>
        <span class="ml-1">
          <a v-if="item.mall === '스토어팜 (발란)'"
             :href="`https://sell.smartstore.naver.com/o/orderDetail/productOrder/${item.order_detailno}/popup`"
             target="_blank">{{ item.order_detailno }}</a>
          <span v-else>{{ item.order_detailno }}</span>
        </span>
        <b-badge class="ml-3 pointer" variant="light" @click="copyText(item.oid)">OID 복사<i class="fa fa-copy ml-1"></i>
        </b-badge>
      </template>
      <b-row>
        <b-col cols="12" md="8">
          <div class="mb-n2">
            <b-button class="mb-1 mr-1" :href="`/#/goods/${item.goodsno}`" size="sm" variant="outline-info"
                      target="_blank">HUB 상품<i class="fa fa-external-link ml-1"></i></b-button>
            <b-button class="mb-1 mr-1" :href="`/#/shop/${item.shop_id}`" size="sm" variant="outline-info"
                      target="_blank">HUB SHOP<i class="fa fa-external-link ml-1"></i></b-button>
            <b-button class="mb-1 mr-1" v-if="item.mall === '고도몰'"
                      :href="`${MALL_URL}/m2/myp/order/delivery?oid=${item.orderno}|${item.order_detailno}`"
                      size="sm" variant="outline-dark" target="_blank">실시간배송<i class="fa fa-external-link ml-1"></i>
            </b-button>
            <b-button class="mb-1 mr-1" :href="`${MALL_URL}/m2/goods/view.php?goodsno=${item.goodsno}`"
                      size="sm" variant="outline-dark" target="_blank">발란몰<i class="fa fa-external-link ml-1"></i>
            </b-button>
            <b-button class="mb-1 mr-1" v-if="item.delivery_type === '해외'"
                      :href="`https://mooyoul.github.io/refined-unipass/`" size="sm" variant="outline-success"
                      target="_blank">수입통관<i class="fa fa-external-link ml-1"></i></b-button>
          </div>
          <b-row>
            <b-col md="6">
              <div class="title-sm">주문상품정보</div>

              <b-badge :href="`/#/shop/${item.shop_id}`" target="_blank" variant="success">
                {{ shopMap[item.shop_id] ? item.shop_id + '. ' : '' }}{{ item.boutique }}
              </b-badge>
              <b-badge class="ml-1" variant="warning">{{ item.brand }}</b-badge>
              <br/>
              <span v-if="item.goodsType === 'used'" class="text-danger">[빈티지{{ item.usedGrade || '등급없음' }}]</span>
              {{ item.goodsnm }}<br/>
              <small><b>{{ item.option ? item.option.optnm : '사이즈' }}</b></small>
              <b-badge size="sm" variant="secondary">{{ item.size }}</b-badge>
              <b-badge size="sm" variant="info">{{ item.qty }}</b-badge>
              {{ $utils.comma(item.sales_price) }} 원<br/>
              <b-badge v-if="!item.size" variant="danger">※ 옵션 정보를 찾을 수 없습니다.</b-badge>
              <div class="mt-1" v-if="item.img_urls">
                <span v-for="(i, idx) in item.img_urls.slice(0, 5)">
                  <img :id="`imgs-${idx}`" :src="i" class="goods-img" style="height:80px" @click="$utils.open(`${i}`)"/>
                  <b-popover :target="`imgs-${idx}`" placement="bottom" triggers="hover focus">
                    <img :src="i" style="max-width:250px"/>
                  </b-popover>
                </span>
                <span class="ml-1 text-muted" v-if="item.img_urls.length > 5">+{{ item.img_urls.length - 5 }}</span>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="title-sm">상품코드</div>
              <small>발란코드 : </small>
              <a :href="`${MALL_URL}/shop/goods/goods_view.php?goodsno=${item.goodsno}`" target="_blank">
                <b-badge variant="info">{{ item.goodsno }}</b-badge>
              </a>
              <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.goodsno)"><i class="fa fa-copy"></i>
              </b-badge>
              <br/>
              <small>쇼핑몰 상품코드 : </small>
              <a v-if="$C.MALL_GOODS_LINK[item.mall]" :href="`${$C.MALL_GOODS_LINK[item.mall]}${item.mall_goodsno}`"
                 target="_blank">
                <b-badge variant="info">{{ item.mall_goodsno }}</b-badge>
              </a>
              <b-badge v-else variant="light">{{ item.mall_goodsno }}</b-badge>
              <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.mall_goodsno)"><i
                  class="fa fa-copy"></i></b-badge>
              <br/>
              <small>SKU : </small><a
                :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.sku||'').replace(' ', '+')}`"
                target="_blank">
              <b-badge variant="success">{{ item.sku }}</b-badge>
            </a>
              <b-badge v-if="item.sku" class="ml-1 pointer" variant="light" @click="copyText(item.sku)"><i
                  class="fa fa-copy"></i></b-badge>
              <br/>
              <small>파트너 상품코드 : </small>
              <a v-if="item.order_link && item.order_link.startsWith('http') && item.boutique != 'OKMALL'"
                 :href="`https://hub.balaan.io/r?u=${encodeURIComponent(item.order_link)}`" target="_blank">
                <b-badge variant="info">{{ item.boutique_goodsno || 'link' }}</b-badge>
              </a>
              <b-badge v-else variant="light">{{ item.boutique_goodsno }}</b-badge>&nbsp;<i
                class="fa fa-question-circle" v-b-tooltip="'파트너 상품링크가 있다면 파란색으로 표시되며 클릭하면 새 창으로 띄워집니다'"></i>
              <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.boutique_goodsno)"><i
                  class="fa fa-copy"></i></b-badge>
              <br/>
              <small>상품관리코드 : </small><b-badge variant="light">{{item.cf_mng_code}}</b-badge>
              <b-badge v-if="item.cf_mng_code" class="ml-1 pointer" variant="light" @click="copyText(item.cf_mng_code)"><i class="fa fa-copy"></i></b-badge>
              <br/>
            </b-col>
            <b-col cols="2">
              <div class="title-sm">상품속성</div>
              <span :class="`badge alert-${item.delivery_type === '해외' ? 'success' : 'info'}`">
                {{ item.delivery_str }}
              </span>
              <span v-if="!item.cf_order_made" class="badge badge-light">{{ item.deliveryDay }}일</span><br/>
              <template v-if="item.manual">
                <span class="badge alert-danger">파트너관리</span><br/>
              </template>
              <template v-if="item.cf_oneday_delivery === 'Y'">
                <span class="badge alert-danger">오늘도착 가능</span><br/>
              </template>
              <template v-if="item.cf_today_pick">
                <span class="badge alert-success">오늘출발 가능</span><br/>
              </template>
              <template v-if="item.cf_order_made">
                <span class="badge alert-secondary">주문제작</span><br/>
              </template>
              <template v-if="item.goodsType === 'used'">
                <span class="badge alert-warning">빈티지</span><span class="badge badge-light">{{
                  item.usedGrade
                }}</span><br/>
              </template>
              <span v-if="item.cf_gift_packing" class="mr-1 badge badge-danger">포장</span>
              <span v-if="item.cf_store_pickup" class="badge badge-primary">픽업</span>
              <template v-if="item.return_cost">
                <span :class="`badge alert-${{goods: 'primary', goods_but_shop: 'warning'}[item.return_cost_type] || 'success'}`"
                      :title="{goods: '상품별 반품비', goods_but_shop: '상품별 반품비이지만 설정되어있지 않아 SHOP 별 반품비가 적용됨'}[item.return_cost_type] || 'SHOP 별 반품비'">
                  반품 {{$utils.comma(item.return_cost)}} 원
                </span>
              </template>
            </b-col>
          </b-row>

          <template v-if="item.ordershift">
            <hr/>
            <b-btn size="sm" v-b-toggle.ordershift variant="danger">상품이 교체된 주문입니다 (교체 전 상품보기)</b-btn>
            <b-collapse id="ordershift" class="mt-2">
              <div v-for="os in item.ordershift">
                <b-badge class="mr-1" variant="success">{{ os.shop_id }}. {{ os.boutique }}</b-badge>
                <b-badge class="mr-1" variant="warning">{{ os.brand }}</b-badge>
                <a :href="`/#/goods/${os.goodsno}`" target="_blank">
                  <b-badge variant="primary">{{ os.goodsno }}</b-badge>
                </a><br/>
                {{ os.goodsnm }}<br/>
                <b-badge size="sm" variant="secondary">{{ os.size }}</b-badge>
                <span v-if="os.option">
                  : {{ $utils.comma(os.option.goods_price) }} 원
                </span><br/>
                <span v-for="(i, idx) in os.img_urls">
                  <img :id="`imgs-${idx}`" :src="i" style="height:50px" @click="$utils.open(`${i}`)"/>
                  <b-popover :target="`imgs-${idx}`" placement="bottom" triggers="hover focus">
                    <img :src="i" style="max-width:250px"/>
                  </b-popover>
                </span><br/>
                교체일시: {{ os._dt }}
              </div>
            </b-collapse>
          </template>

          <div v-if="item.mall === '고도몰' && item.margin_table">
            <div class="title-sm">발란몰 결제정보<span v-if="item.multiple > 1">(총 {{ item.multiple }}개 상품)</span></div>
            <b-row class="text-center">
              <b-col>
                <b-badge variant="light">발란몰 단위 판매금액</b-badge>
                <br/>
                <span>{{ $utils.comma(item.margin_table.mall.goodsPrice) }}</span>
              </b-col>
              <b-col>
                <b-badge v-if="item.coupon_amount_divided > 0" variant="light" class="pointer" @click="godoCouponInfo">
                  쿠폰금액<i class="fa fa-window-restore ml-1"></i></b-badge>
                <b-badge v-else variant="light">쿠폰할인액</b-badge>
                <br/>
                <span>{{ $utils.comma(item.margin_table.mall.coupon) }}</span>
              </b-col>
              <b-col>
                <b-badge variant="light">최대혜택가</b-badge>
                <br/>
                <span>{{ $utils.comma(item.margin_table.mall.memberPrice) }}</span>
              </b-col>
              <b-col>
                <b-badge variant="light">적립금사용액</b-badge>
                <br/>
                <span>{{ $utils.comma(item.margin_table.mall.emoney) }}</span>
              </b-col>
              <b-col>
                <b-badge variant="light">실결제금액</b-badge>
                <br/>
                <span>{{ $utils.rnc(item.margin_table.mall.orderSettlePrice) }}</span>
              </b-col>
              <b-col>
                <b-badge variant="light" class="pointer" @click="godoSettleInfo">결제수단({{ item.info.pg }})<i
                    class="fa fa-window-restore ml-1"></i></b-badge>
                <br/>
                <span>{{ item.settlekind_name }}</span>
              </b-col>
              <b-col>
                <b-badge variant="light">발란몰 총 결제금액<span v-if="item.multiple > 1">({{ item.multiple }}개 상품)</span></b-badge>
                <br/>
                <span>{{ $utils.rnc(item.margin_table.mall.salesPrice) }}</span>
              </b-col>
            </b-row>
          </div>
          <div v-else-if="item.mall === '스토어팜 (발란)'">
            <div class="title-sm">스마트스토어 결제정보</div>
            <b-row class="text-center">
              <b-col>
                <b-badge variant="light">소비자가</b-badge>
                <br/>
                <span>{{ $utils.comma(item.mall_info.TotalProductAmount) }}</span>
              </b-col>
              <b-col>
                <b-badge variant="light">판매금액</b-badge>
                <br/>
                <span>{{ $utils.comma(item.mall_info.TotalPaymentAmount) }}</span>
              </b-col>
              <b-col>
                <b-badge variant="light">결제수단</b-badge>
                <br/>
                <span>{{ item.settlekind_name }}</span>
              </b-col>
              <b-col>
                <b-badge variant="light">결제금액</b-badge>
                <br/>
                <span>{{ $utils.comma(item.mall_info.TotalPaymentAmount) }}</span>
              </b-col>
            </b-row>
          </div>

          <!-- view 전용 -->
          <div class="title-sm">시간순 상태이력</div>
          <div class="btn-disabled-opacity-1" v-if="item.order_date">
            <b-button-group>
              <b-btn size="sm" variant="outline-dark" disabled>
                주문<br/>{{ item.order_date.slice(2) }}<br/>{{ item.order_dt.slice(11) }}
              </b-btn>
              <b-btn v-if="item.payment_date" size="sm" variant="outline-dark" disabled>
                결제<br/>{{ item.payment_date.slice(5) }}<br/>{{ item.payment_dt.slice(11) }}
              </b-btn>
              <b-btn size="sm" :variant="`${s.code === item.order_status_code ? '' : 'outline-'}${variantMap[s.group]}`"
                     v-for="s in orderedStatus.slice(0, 10)" disabled>
                {{ s.name }}<br/>
                {{ item[s.code + '_date'].slice(5) }}<br/>
                <span v-if="item[s.code + '_dt']">{{ item[s.code + '_dt'].slice(11) }}</span>
              </b-btn>
            </b-button-group>
            <b-button-group v-if="orderedStatus.length > 10">
              <b-btn size="sm" :variant="`${s.code === item.order_status_code ? '' : 'outline-'}${variantMap[s.group]}`"
                     v-for="s in orderedStatus.slice(10)" disabled>
                {{ s.name }}<br/>
                {{ item[s.code + '_date'].slice(5) }}<br/>
                <span v-if="item[s.code + '_dt']">{{ item[s.code + '_dt'].slice(11) }}</span>
              </b-btn>
            </b-button-group>
          </div>


          <b-tabs class="mt-3">
            <b-tab title="주문정보">
              <order-tab class="mt-n3" v-model="item" v-bind="{setField, copyText}"
                         @syncItemOrg="syncItemOrg"></order-tab>
            </b-tab>
            <b-tab title="배송정보">
              <delivery-tab class="mt-n3" v-model="item" ref="deliveryTab"
                            v-bind="{statusCodeMap, delayCodeMap, priceMetaMap, syncItem, setField, unsetField, item_status_claim_set, cki, copyDomesticToAbroad}"></delivery-tab>
            </b-tab>
            <b-tab v-if="$R('QNA_R')" title="1:1 문의">
              <qna-tab class="mt-n3" v-model="item" v-bind="{statusCodeMap, delayCodeMap, syncItem, setField, unsetField, item_status_claim_set, cki}"></qna-tab>
            </b-tab>
          </b-tabs>
        </b-col>

        <!-- 오른쪽 사이드 -->

        <b-col cols="12" md="4" class="border-left">
          <!--          <log-tab v-model="item" v-bind="{statusCodeMap, handleDragover}" @syncItemOrg="syncItemOrg"></log-tab>-->
          <!--          <log-tab :value.sync="item" v-bind="{statusCodeMap, handleDragover}" @syncItemOrg="syncItemOrg"></log-tab>-->
          <log-tab v-bind="{value: item, statusCodeMap, handleDragover}" @syncItemOrg="syncItemOrg"></log-tab>
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ ok, cancel }">
<!--        <b-button v-if="$R('ORDER_X')" variant="danger" @click="$refs.orderShiftModal.prepareOrderShift">-->
<!--          주문상품 변경-->
<!--        </b-button>-->
        <b-button v-if="$R('ORDER_X') || $G('dev')" variant="outline-light" @click="$modal.show({type:'json', item})">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="showDiffModal(item)">
          수정이력
        </b-button>
        <b-button v-if="$R('ORDER_X')" variant="outline-danger" @click="removeOrder">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
        <b-button variant="primary" @click="ok()">
          저장
        </b-button>
      </template>
    </b-modal>

    <b-modal title="변경사항 확인" size="lg" v-model="modal.diff">
      <span v-html="diffHtml"></span>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="()=>{modal.diff=false;modalHide({trigger:'ok'})}">
          저장하고 닫기
        </b-button>
        <b-button size="sm" variant="danger" @click="()=>{modal.diff=false;hideModal()}">
          저장하지 않고 닫기
        </b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

<!--    <order-shift-modal ref="orderShiftModal" v-bind="{item}"></order-shift-modal>-->
  </div>
</template>
