<template>
  <div v-if="ongoing">
    <b-progress v-model="per" :max="100" show-progress animated></b-progress>
    <small>{{id}} 작업을 진행중입니다.
      <span v-if="predict">평균 {{utils.round(predict, 2)}} 초 소요됩니다. &nbsp;</span>
      <span v-if="next"><b>[{{next.job}}]</b> 작업을 진행중이며 {{utils.round(next.stat.avg, 2)}} 초 예상됩니다.</span>
      <span v-else><b>[{{job}}]</b> 작업이 {{elapsed}} 초 걸려서 완료되었습니다. 다음 작업을 진행중입니다.</span>
    </small>
  </div>
</template>

<script>
import * as utils from '@/shared/utils'
export default {
  name: "Progress",
  props: { id: String },
  data() {
    return {
      utils, per:0, ongoing:false, predict:0, job:'', elapsed:0, next:null, intervalId:null
    };
  },
  sockets: {
    prog(obj) {
      // {type:'start', group, predict, stat, next:nextJob[0]}
      // {type:'lap', group, job, elapsed, total_elapsed, predict, next:nextJob[0]}
      // {type:'done', group, elapsed, stat}
      if (obj.group !== this.id) return;
      switch (obj.type) {
        case 'start':
          this.ongoing = true;
          this.per = 0;
          this.next = obj.next;
          this.job = '초기화';
          this.elapsed = 0;
          this.predict = obj.predict ? obj.predict * 1.1 : null; // 보수적
          // TODO: part 별 속도 예상에 따른 캘리브레이션
          this.intervalId = setInterval(_=>{this.per = Math.min(99, this.per + 100 / 5 / (this.predict || 120))}, 200);
          break;
        case 'lap':
          // this.ongoing = true;
          this.predict = obj.predict ? obj.predict * 1.1 : null; // 보수적
          this.per = Math.min(99, this.per, utils.round(obj.total_elapsed / (this.predict || 120) * 100, 2));
          this.job = obj.job;
          this.elapsed = obj.elapsed;
          this.next = obj.next;
          break;
        case 'done':
          this.per = 100;
          this.ongoing = false;
          this.next = null;
          clearInterval(this.intervalId);
          break;
      }
      // console.log(obj);
    },
  },
}
</script>
