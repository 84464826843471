<script>
import DeliveryDropdown from '@/views/modules/DeliveryDropdown.vue';
import {DELIVERY_COMP_PRESET} from 'balaan_constants';

export default {
  name: 'OrderModalDeliveryTab',
  model: {prop: 'value', event: 'change'},
  props: ['value', 'statusCodeMap', 'delayCodeMap', 'priceMetaMap', 'syncItem', 'setField', 'unsetField', 'item_status_claim_set', 'cki', 'copyDomesticToAbroad'],
  components: {DeliveryDropdown},
  data() {
    return {
      today: this.$moment().format('YYYY-MM-DD'),
      formClaim: {
        toCode: '',
        claim_memo: '',
        claim_set: [],
        status_date: '',
      },
      modal: {
        delivery: false,
        date: false,
        claim: false,
      },
      busy: {
        claim: false,
      },
      dateModalStatus: '',
      dateModalValue: '',
      deliveryLog: '',
      message_domestic: '',
      message_abroad: '',
      col_message_domestic: '',
      col_message_abroad: '',
      domestic_del_company_etc: false,
      abroad_del_company_etc: false,
      domestic_del_company_custom: false,
      abroad_del_company_custom: false
    }
  },
  async created() {
    if (this.item.domestic_invoice_no) {
      await this.invoiceVerification(this.item.domestic_del_company, this.item.domestic_invoice_no, this.item.oid, 'domestic');
    }
    if (this.item.abroad_invoice_no) {
      await this.invoiceVerification(this.item.abroad_del_company, this.item.abroad_invoice_no, this.item.oid, 'abroad');
    }
    this.domestic_del_company_etc = !DELIVERY_COMP_PRESET.includes(this.item.domestic_del_company) || this.item.domestic_del_company === '기타';
    this.abroad_del_company_etc = !DELIVERY_COMP_PRESET.includes(this.item.abroad_del_company) || this.item.abroad_del_company === '기타';
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    ckItem: {
      get() {
        return this.cki;
      },
    }
  },
  methods: {
    async setOrderStatus(code) {
      if (code === this.item.order_status_code) return;
      if (!process.env.IS_DEV && !this.$R('ADMIN') && code === 'del_prepare' && this.item.order_status_code === 'order_receive') {
        alert(`배송 준비 상태로 직접 변경할 수 없습니다. 개발팀에 문의해 주세요.`);
        return;
      }
      // 취소요청, 취소승인, 파트너반품, (반품요청-자동계산), 회수접수 상태는 [고객 클레임비용, 파트너 정산비용] 필수(값을 입력하지 않은 undefined CHECK)
      if (['cancel_request', 'cancel_approve', 'refund_partner', 'retrieve_request'].includes(code) && (this.item.claim_cost == undefined || this.item.partner_claim_cost == undefined)) {
        alert(`고객 클레임비용, 파트너 정산비용을 입력해주세요!\n입력된 반품비는 고객님께 알림톡으로 안내됩니다.`);
        return;
      }
      // claim_memo 가 필요한 상태라면 검증한다.
      if (~Object.keys(this.$C.CLAIM_SET).indexOf(code)) {
        this.formClaim.toCode = code;
        this.formClaim.claim_set = this.$C.CLAIM_SET[code];
        this.formClaim.claim_memo = ~this.$C.CLAIM_SET[code].indexOf(this.item.claim_memo) ? this.item.claim_memo : ''; // 이동할 상태에도 동일한 클레임사유가 있을 때만 남겨둔다.
        this.formClaim.status_date = this.$moment().format('YYYY-MM-DD');
        this.modal.claim = true;
        return;
      }
      let j = await this.$api.postJson('/order/setOrderStatus', {
        type: 'set',
        item: {oid: this.item.oid, status_code: code}
      });
      if (!j) return;
      this.syncItem(j);
    },
    async setOrderStatusClaim() {
      this.busy.claim = true;
      let form = this.formClaim;
      if (!form.claim_memo) return alert('클레임사유를 입력해주세요');
      let j = await this.$api.postJson('/order/setOrderStatus', {
        type: 'set', item: {
          oid: this.item.oid,
          status_code: form.toCode,
          claim_memo: form.claim_memo,
          status_date: form.status_date === this.$moment().format('YYYY-MM-DD') ? null : form.status_date
        }
      });
      if (!j) return;
      this.busy.claim = false;
      this.modal.claim = false;
      this.syncItem(j);
    },

    editDateModal(status) {
      this.dateModalValue = this.item[status + '_date'];
      this.dateModalStatus = status;
      this.modal.date = true;
    },
    async editDate() {
      let j = await this.$api.postJson('/order/setOrderDate', {
        item: {
          oid: this.item.oid,
          status_code: this.dateModalStatus,
          date: this.dateModalValue
        }
      });
      if (j) {
        this.syncItem(j);
        this.modal.date = false;
      }
    },

    async removeStatus() {
      if (confirm(`${this.statusCodeMap[this.dateModalStatus].name} 상태를 제거하시겠습니까?`)) {
        let j = await this.$api.postJson('/order/setOrderStatus', {
          type: 'remove',
          item: {oid: this.item.oid, status_code: this.dateModalStatus}
        });
        if (j) {
          this.syncItem(j);
          this.modal.date = false;
        }
      }
    },

    async setAbroadComp(comp) {
      this.inputCompany(comp, 'abroad');
      this.$forceUpdate();
    },
    async setDomesticComp(comp) {
      await this.inputCompany(comp, 'domestic');
      this.$forceUpdate();
    },
    async setGlobalComp(comp) {
      await this.inputCompany(comp, 'global');
      this.$forceUpdate();
    },
    setGlobalRefundDate(value) {
      if (value) {
        if (!this.item.global_refund_date) this.item.global_refund_date = this.$utils.kstD();
      } else {
        this.item.global_refund_date = null;
      }
      this.$forceUpdate();
    },
    setReturnComp(comp) {
      this.item.return_del_company = comp;
      this.$forceUpdate();
    },
    setReturnShippingComp(comp) {
      this.item.return_shipping_del_company = comp;
      this.$forceUpdate();
    },
    async inputCompany(comp, type) {
      if (comp === '기타(직접 입력)') {
        comp = '';
        this[`${type}_del_company_etc`] = true;
        this[`${type}_del_company_custom`] = true;
        this.$refs[`${type}_input`].focus();
      }
      this.item[`${type}_del_company`] = comp;
      await this.invoiceVerification(this.item[`${type}_del_company`],this.item[`${type}_invoice_no`], this.item.oid, type);
    },

    async invoiceVerification(comp, invoiceNo, oid, type) {
      if (!invoiceNo || !comp) {
        this[`message_${type}`] = '';
        this.ckItem[`${type}_invoice`] = true;
      } else {
        this[`col_message_${type}`] = 'black';
        this[`message_${type}`] = '운송장 번호 확인 중입니다';
        this.ckItem.ckInvoice = false;
        const j = await this.$api.getJson(`/order/invoiceVerification?invoiceNo=` + invoiceNo + `&oid=` + oid + `&comp=` + comp);

        if (j.res.bo) {
          this[`message_${type}`] = '유효한 운송장입니다';
          this[`col_message_${type}`] = 'blue';
          this.ckItem[`${type}_invoice`] = true;
        } else {
          if (j.res.ckComp === 1) {
            this[`message_${type}`] = '운송장 유효성 체크가 불가능한 택배사입니다'
            this[`col_message_${type}`] = 'black';
            this.ckItem[`${type}_invoice`] = true;
          } else if (j.res.ckComp === 3) {
            this[`message_${type}`] = '유효하지 않은 운송장입니다';
            this[`col_message_${type}`] = 'red';
            this.ckItem[`${type}_invoice`] = false;
          }
        }
        this.ckItem.ckInvoice = true;
      }
      this.$forceUpdate();
    },

    /**
     * 고객 클레임 비용 Item 을 추가한다. Item 의 합이 최종 정산되는 클레임 비용이다.
     * 기본적으로 claim_cost 와 동일한 값에서 시작한다.
     */
    addClaimItem() {
      this.item.claim_item = this.item.claim_item || [];
      this.item.claim_item.push({name: '', cost: 0});
      this.calcClaimItem();
    },
    removeClaimItem(item) {
      this.item.claim_item.splice(this.item.claim_item.indexOf(item), 1);
      this.calcClaimItem();
    },
    calcClaimItem() {
      this.item.claim_cost = this.item.claim_item.filter(e => e.cost).map(e => e.cost).reduce((a, b) => a + b, 0);
      this.$forceUpdate();
    },

    /**
     * 파트너 클레임 비용 Item 을 추가한다. Item 의 합이 최종 정산되는 클레임 비용이다.
     * 기본적으로 claim_cost 와 동일한 값에서 시작한다.
     */
    addParterClaimItem() {
      this.item.partner_claim_item = this.item.partner_claim_item || [];
      this.item.partner_claim_item.push({name: '', cost: 0});
      this.calcParterClaimItem();
    },
    removePartnerClaimItem(item) {
      this.item.partner_claim_item.splice(this.item.partner_claim_item.indexOf(item), 1);
      this.calcParterClaimItem();
    },
    calcParterClaimItem() {
      this.item.partner_claim_cost = this.item.partner_claim_item.filter(e => e.cost).map(e => e.cost).reduce((a, b) => a + b, 0);
      this.$forceUpdate();
    },

    async deliverHistory(comp, no, item, type) {
      let j;
      j = await this.$api.getJson(`/order/selectDeliveryTracker?oid=${this.item.oid}&comp=${comp}&no=${no}&type=${type}`);

      if (j.ok && j.doc != null) {
        let deliveryLogData = {
          carrier: {
            name: comp, //각 택배사명으로 변경;
            tel: ''
          }
        };
        if (type === 'domestic') {
          deliveryLogData.shipping = [j.doc.del_domestic];
          this.deliveryLog = deliveryLogData;
          this.modal.delivery = true;
        } else if (type === 'abroad') {
          deliveryLogData.shipping = [j.doc.del_abroad];
          this.deliveryLog = deliveryLogData;
          this.modal.delivery = true;
        } else if (type === 'return') {
          deliveryLogData.shipping = [j.doc.retrieve_request];
          this.deliveryLog = deliveryLogData;
          this.modal.delivery = true;
        }
      } else {
        let compCode = this.$C.DELIVERY_COMP_CODE_MAP_FOR_TRACKER[comp.replace(/\s/g, '').toUpperCase()];
        if (compCode) {
          this.deliveryLog = '';
          if (compCode === 'kr.cjlogistics') no = no.replace(/-/g, '');
          try {
            j = await (await fetch(`${this.$C.DELIVERY_CHECKER_LINK}${compCode}/tracks/${no}`)).json();

            if (!j.carrier && j.message) {
              return alert(j.message);
            } else {
              this.deliveryLog = j;
              this.modal.delivery = true;
              return;
            }
          } catch (e) {
            console.error(e);
            return alert('조회중 오류가 발생했습니다');
          }
        }
        alert('조회 결과가 없습니다.');
      }
    },
  }
}
</script>

<template>
  <div>
    <div class="title-sm">배송정보</div>
    <template v-if="item.logistics === 'balaan'">
      <b-form-group label="1차 배송정보(물류)" :label-cols="3">
        <b-row>
          <b-col sm="4">
            <b-input-group>
              <b-input-group-prepend>
                <delivery-dropdown variant="light" @setCompany="setAbroadComp"></delivery-dropdown>
              </b-input-group-prepend>
              <b-form-input ref="abroad_input" type="text" v-model="item.abroad_del_company" :placeholder="!abroad_del_company_custom ? '1차 배송사' : '직접 입력'" :readonly="!abroad_del_company_etc"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col sm="7">
            <b-input-group>
              <b-form-input type="text"
                            @blur="invoiceVerification(item.abroad_del_company,item.abroad_invoice_no.trim(),item.oid, 'abroad')"
                            v-model="item.abroad_invoice_no" placeholder="1차 운송장"></b-form-input>
              <b-input-group-append
                  v-if="item.abroad_del_company && item.abroad_invoice_no && item.abroad_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_INVOICE_LINK[item.abroad_del_company.toUpperCase()]">
                <b-button variant="outline-success"
                          :href="$C.DELIVERY_INVOICE_LINK[item.abroad_del_company.toUpperCase()](item.abroad_invoice_no)"
                          target="_blank">배송조회
                </b-button>
              </b-input-group-append>
              <b-input-group-append
                  v-else-if="item.abroad_del_company === 'GOODSFLOW' && item.abroad_invoice_no && item.abroad_invoice_no.toString().trim().length >= 9 && item.abroad_invoice_no.startsWith('http')">
                <b-button variant="outline-success" :href="`${item.abroad_invoice_no}`" target="_blank">배송조회</b-button>
              </b-input-group-append>
              <b-input-group-append
                  v-if="item.abroad_del_company && item.abroad_invoice_no && item.abroad_invoice_no.toString().trim().length >= 9 && item.abroad_invoice_no.length <= 20 && $C.DELIVERY_COMP_CODE_MAP_FOR_GOODSFLOW[item.abroad_del_company.replace(/\s/g, '').toUpperCase()]">
                <b-button variant="outline-primary"
                          @click="deliverHistory(item.abroad_del_company, item.abroad_invoice_no, item, 'abroad')">배송이력
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <span v-if = "message_abroad" :style="{color: col_message_abroad}">{{message_abroad}}</span>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="2차 배송정보(고객)" :label-cols="3">
        <b-row>
          <b-col cols="4">
            <b-input-group>
              <b-input-group-prepend>
                <delivery-dropdown variant="light" @setCompany="setDomesticComp"></delivery-dropdown>
              </b-input-group-prepend>
              <b-form-input ref="domestic_input" type="text" v-model="item.domestic_del_company" :placeholder="!domestic_del_company_custom ? '2차 배송사' : '직접 입력'" :readonly="!domestic_del_company_etc"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col sm="7">
            <b-input-group>
              <b-form-input type="text"
                            @blur="invoiceVerification(item.domestic_del_company,item.domestic_invoice_no.trim(),item.oid, 'domestic')"
                            v-model="item.domestic_invoice_no" placeholder="2차 운송장"></b-form-input>
              <b-input-group-append
                  v-if="item.domestic_del_company && item.domestic_invoice_no && item.domestic_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_INVOICE_LINK[item.domestic_del_company.toUpperCase()]">
                <b-button variant="outline-success"
                          :href="$C.DELIVERY_INVOICE_LINK[item.domestic_del_company.toUpperCase()](item.domestic_invoice_no)"
                          target="_blank">배송조회
                </b-button>
              </b-input-group-append>
              <b-input-group-append
                  v-if="item.domestic_del_company && item.domestic_invoice_no && item.domestic_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_COMP_CODE_MAP_FOR_GOODSFLOW[item.domestic_del_company.replace(/\s/g, '').toUpperCase()]">
                <b-button variant="outline-primary"
                          @click="deliverHistory(item.domestic_del_company, item.domestic_invoice_no, item, 'domestic')">배송이력
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <span v-if = "message_domestic" :style="{color: col_message_domestic}">{{message_domestic}}</span>
          </b-col>
        </b-row>
      </b-form-group>
    </template>
    <template v-else-if="item.delivery_type === '해외'">
      <b-form-group label="해외직배송정보 1차" :label-cols="3">
        <b-row>
          <b-col sm="4">
            <b-input-group>
              <b-input-group-prepend>
                <delivery-dropdown variant="light" @setCompany="setAbroadComp"></delivery-dropdown>
              </b-input-group-prepend>
              <b-form-input ref="abroad_input" type="text" v-model="item.abroad_del_company"
                            :placeholder="!abroad_del_company_custom ? '해외배송사' : '직접 입력'" :readonly="!abroad_del_company_etc || item.abroad_direct"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col sm="7">
            <b-input-group>
              <b-form-input type="text"
                            @blur="invoiceVerification(item.abroad_del_company,item.abroad_invoice_no.trim(),item.oid, 'abroad')"
                            v-model="item.abroad_invoice_no" placeholder="해외운송장"
                            :readonly="item.abroad_direct"></b-form-input>
              <b-input-group-append
                  v-if="item.abroad_del_company && item.abroad_invoice_no && item.abroad_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_INVOICE_LINK[item.abroad_del_company.toUpperCase()]">
                <b-button variant="outline-success"
                          :href="$C.DELIVERY_INVOICE_LINK[item.abroad_del_company.toUpperCase()](item.abroad_invoice_no)"
                          target="_blank">배송조회
                </b-button>
              </b-input-group-append>
              <b-input-group-append
                  v-else-if="item.abroad_del_company === 'GOODSFLOW' && item.abroad_invoice_no && item.abroad_invoice_no.toString().trim().length >= 9 && item.abroad_invoice_no.startsWith('http')">
                <b-button variant="outline-success" :href="`${item.abroad_invoice_no}`" target="_blank">배송조회</b-button>
              </b-input-group-append>
              <b-input-group-append
                  v-if="item.abroad_del_company && item.abroad_invoice_no && item.abroad_invoice_no.toString().trim().length >= 9 && item.abroad_invoice_no.length <= 20 && $C.DELIVERY_COMP_CODE_MAP_FOR_GOODSFLOW[item.abroad_del_company.replace(/\s/g, '').toUpperCase()]">
                <b-button variant="outline-primary"
                          @click="deliverHistory(item.abroad_del_company, item.abroad_invoice_no, item, 'abroad')">배송이력
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <span v-if = "message_abroad" :style="{color: col_message_abroad}">{{message_abroad}}</span>

          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="해외직배송정보 2차" :label-cols="3">
        <b-row>
          <b-col>
            <small>
              <b-form-checkbox class="d-flex" v-model="item.abroad_direct" v-if="item.logistics === 'direct' && item.delivery_type === '해외'"
                               @input="copyDomesticToAbroad">해외 직배송 (해외에서 고객에게 바로 배송되는 경우)</b-form-checkbox>
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-input-group>
              <b-input-group-prepend>
                <delivery-dropdown variant="light" @setCompany="setDomesticComp"></delivery-dropdown>
              </b-input-group-prepend>
              <b-form-input ref="domestic_input" type="text" v-model="item.domestic_del_company" :placeholder="!domestic_del_company_custom ? '국내배송사' : '직접 입력'"
                            :readonly="!domestic_del_company_etc || item.abroad_direct"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col sm="7">
            <b-input-group>
              <b-form-input type="text"
                            @blur="invoiceVerification(item.domestic_del_company,item.domestic_invoice_no.trim(),item.oid, 'domestic')"
                            v-model="item.domestic_invoice_no" placeholder="국내운송장" :readonly="item.abroad_direct"></b-form-input>
              <b-input-group-append
                  v-if="item.domestic_del_company && item.domestic_invoice_no && item.domestic_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_INVOICE_LINK[item.domestic_del_company.toUpperCase()]">
                <b-button variant="outline-success"
                          :href="$C.DELIVERY_INVOICE_LINK[item.domestic_del_company.toUpperCase()](item.domestic_invoice_no)"
                          target="_blank">배송조회
                </b-button>
              </b-input-group-append>
              <b-input-group-append
                  v-if="item.domestic_del_company && item.domestic_invoice_no && item.domestic_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_COMP_CODE_MAP_FOR_GOODSFLOW[item.domestic_del_company.replace(/\s/g, '').toUpperCase()]">
                <b-button variant="outline-primary"
                          @click="deliverHistory(item.domestic_del_company, item.domestic_invoice_no, item, 'domestic')">배송이력
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <span v-if = "message_domestic" :style="{color: col_message_domestic}">{{message_domestic}}</span>
          </b-col>
        </b-row>
      </b-form-group>
    </template>
    <b-form-group v-else-if="item.delivery_type === '국내'" label="국내직배송정보" :label-cols="3">
      <b-row>
        <b-col cols="4">
          <b-input-group>
            <b-input-group-prepend>
              <delivery-dropdown variant="light" @setCompany="setDomesticComp"
                                 v-bind:disabled="!($R('ADMIN') || $R('ORDER_W')) && item.tp_dt"></delivery-dropdown>
            </b-input-group-prepend>
            <b-form-input ref="domestic_input" type="text" v-model="item.domestic_del_company" :placeholder="!domestic_del_company_custom ? '국내배송사' : '직접 입력'"
                          :disabled="!($R('ADMIN') || $R('ORDER_W')) && item.tp_dt" :readonly="!domestic_del_company_etc"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col sm="7">
          <b-input-group>
            <b-form-input type="text"
                          @blur="invoiceVerification(item.domestic_del_company,item.domestic_invoice_no.trim(),item.oid, 'domestic')"
                          v-model="item.domestic_invoice_no" placeholder="국내운송장" class="readonly"
                          :readonly="!($R('ADMIN') || $R('ORDER_W')) && item.tp_dt"></b-form-input>
            <b-input-group-append
                v-if="item.domestic_del_company && item.domestic_invoice_no && item.domestic_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_INVOICE_LINK[item.domestic_del_company.toUpperCase()]">
              <b-button variant="outline-success"
                        :href="$C.DELIVERY_INVOICE_LINK[item.domestic_del_company.toUpperCase()](item.domestic_invoice_no)"
                        target="_blank">배송조회
              </b-button>
            </b-input-group-append>
            <b-input-group-append
                v-if="item.domestic_del_company && item.domestic_invoice_no && item.domestic_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_COMP_CODE_MAP_FOR_GOODSFLOW[item.domestic_del_company.replace(/\s/g, '').toUpperCase()]">
              <b-button variant="outline-primary"
                        @click="deliverHistory(item.domestic_del_company, item.domestic_invoice_no, item, 'domestic')">배송이력
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <span v-if = "message_domestic" :style="{color: col_message_domestic}">{{message_domestic}}</span>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group v-if="item.mall === '글로벌'" label="글로벌배송정보" :label-cols="3">
      <b-row>
        <b-col cols="4">
          <b-input-group>
            <b-input-group-prepend>
              <delivery-dropdown variant="light" @setCompany="setGlobalComp"
                                 v-bind:disabled="!($R('ADMIN') || $R('ORDER_W')) && item.tp_dt"></delivery-dropdown>
            </b-input-group-prepend>
            <b-form-input ref="domestic_input" type="text" v-model="item.global_del_company" placeholder="글로벌배송사"
                          :disabled="!($R('ADMIN') || $R('ORDER_W'))"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col sm="7">
          <b-input-group>
            <b-form-input type="text" v-model="item.global_invoice_no" placeholder="글로벌운송장"/>
            <b-input-group-append v-if="item.global_del_company && item.global_invoice_no && item.global_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_INVOICE_LINK[item.global_del_company.toUpperCase()]">
              <b-button variant="outline-success" :href="$C.DELIVERY_INVOICE_LINK[item.global_del_company.toUpperCase()](item.global_invoice_no)" target="_blank">배송조회</b-button>
            </b-input-group-append>
            <b-input-group-append v-if="item.global_del_company && item.global_invoice_no && item.global_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_COMP_CODE_MAP_FOR_GOODSFLOW[item.global_del_company.replace(/\s/g, '').toUpperCase()]">
              <b-button variant="outline-primary" @click="deliverHistory(item.global_del_company, item.global_invoice_no, item, 'global')">배송이력</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group v-if="item.mall === '글로벌'" label="글로벌반품정보" :label-cols="3" label-class="align-content-center">
      <div class="col-form-label">
        <b-button :variant="!item.global_refund_date ? 'primary' : 'outline-primary'" size="sm" class="mr-1" @click="setGlobalRefundDate(false)">글로벌반품없음</b-button>
        <b-button :variant="item.global_refund_date ? 'primary' : 'outline-primary'" size="sm" @click="setGlobalRefundDate(true)">글로벌반품있음</b-button>
        <span class="d-inline ml-2">{{ this.item.global_refund_date }}</span>
      </div>
    </b-form-group>

    <div class="title-sm">개인통관고유부호</div>
    <b-row>
      <b-col md="4">
        <small>수령자 실명</small><br/>
        <b-form-input type="text" v-model="item.pccc_name" placeholder="수령자 실명"></b-form-input>
      </b-col>
      <b-col md="4">
        <small>수령자 통관 휴대폰번호</small><br/>
        <b-form-input type="text" v-model="item.pccc_mobile" placeholder="수령자 통관 휴대폰번호"></b-form-input>
      </b-col>
      <b-col md="4">
        <small>개인통관고유부호</small><br/>
        <b-form-input type="text" v-model="item.pccc" placeholder="개인통관고유부호"></b-form-input>
      </b-col>
    </b-row>

    <div class="title-sm">운영정보</div>
    <b-row>
      <b-col md="4">
        <small>리얼패킹 ID</small>
        <b-form-input type="text" v-model="item.real_packing_id" placeholder="리얼패킹 ID"></b-form-input>
        <label class="col-form-label" v-if="item.real_packing_url"><a :href="item.real_packing_url" target="_blank">영상링크
          <i class="fa fa-external-link"></i></a></label>
      </b-col>
      <!--<b-col cols="4">
        <small>입고차수</small><br/>
        <label class="col-form-label">{{item.ipgo_date_count}}</label>
      </b-col>-->
      <b-col md="4">
        <small>클레임사유</small><br/>
        <b-input-group>
          <b-input-group-prepend>
            <b-dropdown variant="light" :disabled="!item_status_claim_set.length">
              <b-dropdown-item v-for="n of item_status_claim_set" @click="_ => {item.claim_memo = n;$forceUpdate()}" :key="n">
                {{ n }}
              </b-dropdown-item>
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-input type="text" v-model="item.claim_memo" placeholder=""
                        :disabled="!$R('CLAIM') && !item_status_claim_set.length"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col md="4">
        <small>고객 클레임비용
          <span class="text-danger">(필독 <i class="fa fa-question-circle"
                                           v-b-tooltip="'기본값: SHOP 의 반품비용, 하자 등 고객 귀책사유가 아닐 때는 비용을 조절해주세요. 고객에게서 차감할 금액으로, 이 값은 0 이상이어야 합니다.'"></i>)</span>
        </small><br/>
        <b-form-group>
          <b-input-group>
            <b-form-input type="text" v-model.number="item.claim_cost" placeholder="" disabled></b-form-input>
            <b-input-group-append>
              <b-dropdown ref="partner_claim_cost_dropdown" right
                          :disabled="!$R('CLAIM') && !item.order_status_code.in('refund_request', 'refund_partner')">
                <b-dropdown-form>
                  <template v-if="item.claim_item">
                    <b-row class="mt-2" v-for="c in item.claim_item">
                      <template v-if="c.fixed">
                        <b-col>{{ c.name }}</b-col>
                        <b-col class="text-right">{{ $utils.comma(c.cost) }}</b-col>
                      </template>
                      <template v-else>
                        <b-col cols="1">
                          <div class="col-form-label" @click="removeClaimItem(c)"><i class="fa fa-close"></i>
                          </div>
                        </b-col>
                        <b-col>
                          <b-input v-model="c.name" placeholder="항목명"></b-input>
                        </b-col>
                        <b-col>
                          <b-input class="text-right" @input="calcClaimItem" v-model.number="c.cost"></b-input>
                        </b-col>
                      </template>
                    </b-row>
                  </template>
                  <div class="m-2" v-else>
                    항목이 없습니다.
                  </div>
                  <b-button class="mt-2 pull-right" size="sm" @click="addClaimItem">항목 추가</b-button>
                </b-dropdown-form>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button disabled>값을 변경한 뒤에는 꼭 저장해주세요</b-dropdown-item-button>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <small>
          파트너 정산비용
          <span class="text-danger">(필독 <i class="fa fa-question-circle"
                                           v-b-tooltip="`오늘도착일 때 발란의 비용부담으로 인해 [정상배송시 ${priceMetaMap.oneday_delivery_surcharge}원], [반품시 반품비용에서 ${priceMetaMap.oneday_return_rate}%] 을 정산금액에서 제외합니다. 이 금액이 플러스이면 파트너에게 추가 정산이 되고, 마이너스이면 파트너의 정산 금액에서 차감합니다`"></i>)</span>
        </small><br/>
        <b-form-group>
          <b-input-group>
            <b-form-input type="text" v-model.number="item.partner_claim_cost" placeholder="" disabled></b-form-input>
            <b-input-group-append>
              <b-dropdown ref="partner_claim_cost_dropdown" right
                          :disabled="!$R('CLAIM') && !item.order_status_code.in('refund_request', 'refund_partner')">
                <b-dropdown-form>
                  <template v-if="item.partner_claim_item">
                    <b-row class="mt-2" v-for="c in item.partner_claim_item">
                      <template v-if="c.fixed">
                        <b-col>{{ c.name }}</b-col>
                        <b-col class="text-right">{{ $utils.comma(c.cost) }}</b-col>
                      </template>
                      <template v-else>
                        <b-col cols="1">
                          <div class="col-form-label" @click="removePartnerClaimItem(c)"><i class="fa fa-close"></i>
                          </div>
                        </b-col>
                        <b-col>
                          <b-input v-model="c.name" placeholder="항목명"></b-input>
                        </b-col>
                        <b-col>
                          <b-input class="text-right" @input="calcParterClaimItem" v-model.number="c.cost"></b-input>
                        </b-col>
                      </template>
                    </b-row>
                  </template>
                  <div class="m-2" v-else>
                    항목이 없습니다.
                  </div>
                  <b-button class="mt-2 pull-right" size="sm" @click="addParterClaimItem">항목 추가</b-button>
                </b-dropdown-form>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button disabled>값을 변경한 뒤에는 꼭 저장해주세요</b-dropdown-item-button>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="8">
        <small>파트너 정산비용 수정이유</small>
        <b-form-input type="text" v-model="item.partner_claim_modified_reason" placeholder=""></b-form-input>
      </b-col>
      <b-col cols="12">
        <small>고객 클레임 내용</small><br/>
        <b-form-input v-if="item.user_claim_memo" type="text" v-model="item.user_claim_memo" placeholder="" plaintext></b-form-input>
        <div v-else class="text-muted pb-2 fs-12">고객 클레임 내용이 없습니다.</div>
      </b-col>
      <b-col cols="12">
        <small>고객 클레임 이미지<span v-if="item.user_claim_img">({{ item.user_claim_img.length }} 개)</span></small><br/>
        <div v-if="item.user_claim_img" style="min-height:60px">
          <template v-for="(img, idx) in item.user_claim_img">
            <a :href="img" target="_blank"><img :id="`user_claim_${idx}`" :src="img"
                                                :style="{height:'80px', marginRight:'10px', border:'1px solid #ccc'}"/></a>
            <b-popover :target="`user_claim_${idx}`" placement="top" triggers="hover focus">
              <img :src="img" style="max-width:250px"/>
            </b-popover>
          </template>
        </div>
        <div v-else class="text-muted pb-2 fs-12">고객 클레임 이미지가 없습니다.</div>
      </b-col>

      <b-col cols="12">
        <small>반품 메모</small><br/>
        <b-form-input type="text" v-model="item.return_memo" placeholder="특이사항, 반품지 주소 등"></b-form-input>
      </b-col>
      <b-col cols="12" class="mt-1">
        <small>반품 배송정보</small><br/>
        <b-row>
          <b-col cols="4">
            <b-input-group>
              <b-input-group-prepend>
                <delivery-dropdown variant="light" @setCompany="setReturnComp"></delivery-dropdown>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="item.return_del_company" placeholder="반품 배송사"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col sm="7">
            <b-input-group>
              <b-form-input type="text" v-model="item.return_invoice_no" placeholder="반품 운송장"></b-form-input>
              <b-input-group-append
                  v-if="item.return_del_company && item.return_invoice_no && item.return_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_INVOICE_LINK[item.return_del_company.toUpperCase()]">
                <b-button variant="outline-success"
                          :href="`${$C.DELIVERY_INVOICE_LINK[item.return_del_company.toUpperCase()](item.return_invoice_no)}`"
                          target="_blank">배송조회
                </b-button>
              </b-input-group-append>
              <b-input-group-append
                  v-if="item.return_del_company && item.return_invoice_no && item.return_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_COMP_CODE_MAP_FOR_GOODSFLOW[item.return_del_company.replace(/\s/g, '').toUpperCase()]">
                <b-button variant="outline-primary"
                          @click="deliverHistory(item.return_del_company, item.return_invoice_no, item, 'return')">배송이력
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mt-1">
        <small>반품 출고 배송정보</small><i class="fa fa-question-circle"
                                    v-b-tooltip="'물류센터에서 파트너로 배송되는 상품의 배송정보입니다.'"></i><br/>
        <b-row>
          <b-col cols="4">
            <b-input-group>
              <b-input-group-prepend>
                <delivery-dropdown variant="light" @setCompany="setReturnShippingComp"></delivery-dropdown>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="item.return_shipping_del_company"
                            placeholder="반품 출고 배송사"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col sm="7">
            <b-input-group>
              <b-form-input type="text" v-model="item.return_shipping_invoice_no"
                            placeholder="반품 출고 운송장"></b-form-input>
              <b-input-group-append
                  v-if="item.return_shipping_del_company && item.return_shipping_invoice_no && item.return_shipping_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_INVOICE_LINK[item.return_shipping_del_company.toUpperCase()]">
                <b-button variant="outline-success"
                          :href="$C.DELIVERY_INVOICE_LINK[item.return_shipping_del_company.toUpperCase()](item.return_shipping_invoice_no)"
                          target="_blank">배송조회
                </b-button>
              </b-input-group-append>
              <b-input-group-append
                  v-if="item.return_shipping_del_company && item.return_shipping_invoice_no && item.return_shipping_invoice_no.toString().trim().length >= 9 && $C.DELIVERY_COMP_CODE_MAP_FOR_GOODSFLOW[item.return_shipping_del_company.replace(/\s/g, '').toUpperCase()]">
                <b-button variant="outline-primary"
                          @click="deliverHistory(item.return_shipping_del_company, item.return_shipping_invoice_no, item, 'return')">
                  배송이력
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div class="title-sm">지연정보</div>
    <small>지연이력</small>
    <div v-if="(item.delay || item.delay_reund) && (item.delay || []).concat(item.delay_refund || []).length">
      <template v-for="i in (item.delay || []).concat(item.delay_refund || [])">
        <b-badge class="ml-1" variant="danger"
                 v-if="delayCodeMap[i]" :title="delayCodeMap[i] && delayCodeMap[i].desc" :key="i">
          {{ delayCodeMap[i].name }}
        </b-badge>
      </template>
    </div>
    <div v-else>
      <b-badge>없음</b-badge>
    </div>
    <small>1차 딜레이</small><br/>
    <b-button-group>
      <b-button @click="unsetField('delay_1st', true)" size="sm"
                :variant="`${item.delay_1st == null ? '' : 'outline-'}light`">없음
      </b-button>
      <b-button v-for="e in $C.DELAY_STATUS" @click="setField('delay_1st', e.code)" size="sm"
                :variant="`${e.code === item.delay_1st ? '' : 'outline-'}warning`" :key="e.code">{{ e.name }}({{ e.code }})
      </b-button>
    </b-button-group>
    <br/>
    <small>2차 딜레이</small><br/>
    <b-button-group>
      <b-button @click="unsetField('delay_2nd', true)" size="sm"
                :variant="`${item.delay_2nd == null ? '' : 'outline-'}light`">없음
      </b-button>
      <b-button v-for="e in $C.DELAY_STATUS" @click="setField('delay_2nd', e.code)" size="sm"
                :variant="`${e.code === item.delay_2nd ? '' : 'outline-'}danger`" :key="e.code">{{ e.name }}({{ e.code }})
      </b-button>
    </b-button-group>
    <br/>

    <div class="title-sm">상태정보</div>
    <b-button-group v-for="[group, variant] in [['order','primary']]" class="mb-1" :key="group">
      <b-button @click="setOrderStatus(s.code)" size="sm"
                :variant="`${s.code === item.order_status_code ? '' : 'outline-'}${variant}`"
                v-for="s in $C.ORDER_STATUS_CODE.filter(e => e.group === group)" :key="s.code"
                :disabled="!($R('ADMIN') || $R('ORDER_W')) && item.tp_dt ? group === 'domestic' : false">{{ s.name }}
      </b-button>
    </b-button-group>
    <br/>
    <b-button-group v-for="[group, variant] in [['abroad','warning'], ['domestic','success']]" class="mb-1" :key="group">
      <b-button @click="setOrderStatus(s.code)" size="sm"
                :variant="`${s.code === item.order_status_code ? '' : 'outline-'}${variant}`"
                v-for="s in $C.ORDER_STATUS_CODE.filter(e => e.group === group)" :key="s.code"
                :disabled="!($R('ADMIN') || $R('ORDER_W')) && item.tp_dt ? group === 'domestic' : false">{{ s.name }}
      </b-button>
    </b-button-group>
    <br/>
    <b-button-group v-for="[group, variant] in [['after','danger']]" class="mb-1" :key="group">
      <b-button @click="setOrderStatus(s.code)" size="sm"
                :variant="`${s.code === item.order_status_code ? '' : 'outline-'}${variant}`"
                v-for="s in $C.ORDER_STATUS_CODE.filter(e => e.group === group)" :key="s.code"
                :disabled="!($R('ADMIN') || $R('ORDER_W')) && item.tp_dt ? group === 'domestic' : false">{{ s.name }}
      </b-button>
    </b-button-group>
    <br/>
    <b-button-group v-for="[group, variant] in [['global', 'secondary']]" class="mb-1" :key="group">
      <b-button @click="setOrderStatus(s.code)" size="sm"
                :variant="`${s.code === item.order_status_code ? '' : 'outline-'}${variant}`"
                v-for="s in $C.ORDER_STATUS_CODE.filter(e => e.group === group)" :key="s.code"
                :disabled="!($R('ADMIN') || $R('ORDER_W')) && item.tp_dt ? group === 'domestic' : false">{{ s.name }}
      </b-button>
    </b-button-group>
    <br/>

    <b-row>
      <b-col sm="2">
        주문일<br/>
        <span :style="item.order_dt ? 'font-weight:500' : ''"
              v-b-tooltip="item.order_dt || ''">{{ item.order_date }} </span>
        <b-badge variant="light" v-if="item.order_date === today">Today</b-badge>
        <br/>
      </b-col>
      <template v-for="s in $C.ORDER_STATUS_CODE">
        <b-col v-if="item[s.code+'_date']" class="pb-2" sm="2" :key="s.code">
          {{ s.name }}
          <b-badge variant="danger" v-if="s.name === item.order_status">NOW</b-badge>
          <br/>
          <span :style="item[s.code+'_dt'] ? 'font-weight:500' : ''"
                v-b-tooltip="item[s.code+'_dt'] || ''">{{ item[s.code + '_date'] }} </span>
          <b-badge variant="light" v-if="item[s.code+'_date'] === today">Today</b-badge>
          <i class="fa fa-pencil text-muted" style="cursor:pointer" @click="editDateModal(s.code)"></i>
        </b-col>
      </template>
    </b-row>
    <b-form-group label="철회이력" :label-cols="2" v-if="item.withdraw_flag">
      <div class="col-form-label">시각: {{ item.withdraw_flag._dt }}, IP: {{ item.withdraw_flag.ip }}</div>
    </b-form-group>

    <b-modal :title="`[${statusCodeMap[dateModalStatus] && statusCodeMap[dateModalStatus].name}] 의 일자수정 또는 상태삭제`"
             v-model="modal.date">
      <date-input class="form-inline" v-model="dateModalValue" @change="v=>{dateModalValue = v}"></date-input>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="editDate()">
          수정
        </b-button>
        <b-button size="sm" variant="danger" @click="removeStatus()">
          삭제
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="`[${statusCodeMap[formClaim.toCode] && statusCodeMap[formClaim.toCode].name}] 상태로 변경 및 클레임사유 지정`"
             v-model="modal.claim">
      <div><small>상태 일자</small></div>
      <date-input class="form-inline" v-model="formClaim.status_date"
                  @change="v=>{formClaim.status_date=v}"></date-input>
      <div><small>클레임사유</small></div>
      <b-input-group>
        <b-input-group-prepend>
          <b-dropdown variant="light">
            <b-dropdown-item v-for="n of formClaim.claim_set" @click="_=>{formClaim.claim_memo = n;$forceUpdate()}" :key="n">
              {{ n }}
            </b-dropdown-item>
          </b-dropdown>
        </b-input-group-prepend>
        <b-form-input type="text" v-model="formClaim.claim_memo" placeholder=""></b-form-input>
      </b-input-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="setOrderStatusClaim()" :disabled="busy.claim">
          변경
          <b-spinner class="ml-1" small v-if="busy.claim"></b-spinner>
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal title="배송이력" size="lg" v-model="modal.delivery" ok-only ok-title="닫기">
      <div v-if="deliveryLog === ''" class="text-center text-info my-4">
        <b-spinner class="align-middle"></b-spinner>
      </div>
      <div v-else-if="deliveryLog.shipping.length > 1">
        <b-tabs>
          <b-tab v-for="(delivery, index) in deliveryLog.shipping" :title="`${index}`">
            <b-row v-if="delivery && delivery.state">
              <b-col cols="3">
                <small>택배사</small><br/>
                <b>{{ deliveryLog.carrier.name }}</b><br/>
                <small class="text-muted">{{ deliveryLog.carrier.tel }}</small>
              </b-col>
              <b-col cols="3">
                <small>현재상태</small><br/>
                <b>{{ delivery.state.text }}</b><br/>
              </b-col>
              <b-col cols="3">
                <small>From</small><br/>
                <b>{{ delivery.from.name || delivery.from.address }}</b><br/>
                <small v-if="delivery.from.time"
                       class="text-muted">{{ delivery.from.time.split('+')[0].replace('T', ' ') }}</small>
              </b-col>
              <b-col cols="3">
                <small>To</small><br/>
                <b>{{ delivery.to.name || delivery.to.address }}</b><br/>
                <small v-if="delivery.to.time"
                       class="text-muted">{{ delivery.to.time.split('+')[0].replace('T', ' ') }}</small>
              </b-col>
            </b-row>
            <div v-for="p in delivery.progresses" v-if="p.status && p.location">  <!--v-b-popover.hover.right="i.msg"-->
              <hr/>
              <div class="message">
                <div>
                  <small class="text-muted" v-if="p.status">{{ p.status.text }}</small>
                  <small class="text-muted float-right mt-1">{{ p.time.split('+')[0].replace('T', ' ') }}</small>
                </div>
                <small class="text-muted">
                  <i class="icon-location-pin"></i>&nbsp;&nbsp;{{ p.location.name }}
                </small>
                <div class="font-weight-bold">{{ p.description }}</div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div v-else>
        <b-row>
          <b-col cols="3">
            <small>택배사</small><br/>
            <b>{{ deliveryLog.carrier.name }}</b><br/>
            <small class="text-muted">{{ deliveryLog.carrier.tel }}</small>
          </b-col>
          <b-col cols="3">
            <small>현재상태</small><br/>
            <b>{{ deliveryLog.shipping[0] && deliveryLog.shipping[0].state.text || '-' }}</b><br/>
          </b-col>
          <b-col cols="3">
            <small>From</small><br/>
            <b>{{
                deliveryLog.shipping[0] ? (deliveryLog.shipping[0].from.name || deliveryLog.shipping[0].from.address) : '-'
              }}</b><br/>
            <small v-if="deliveryLog.shipping[0] && deliveryLog.shipping[0].from.time"
                   class="text-muted">{{ deliveryLog.shipping[0].from.time.split('+')[0].replace('T', ' ') }}</small>
          </b-col>
          <b-col cols="3">
            <small>To</small><br/>
            <b>{{
                deliveryLog.shipping[0] ? (deliveryLog.shipping[0].to.name || deliveryLog.shipping[0].to.address) : '-'
              }}</b><br/>
            <small v-if="deliveryLog.shipping[0] && deliveryLog.shipping[0].to.time"
                   class="text-muted">{{
                deliveryLog.shipping[0] && deliveryLog.shipping[0].to.time.split('+')[0].replace('T', ' ')
              }}</small>
          </b-col>
        </b-row>
        <template v-if="deliveryLog.shipping[0]">
          <div v-if="deliveryLog.shipping[0]" v-for="p in deliveryLog.shipping[0].progresses">
            <!--v-b-popover.hover.right="i.msg"-->
            <hr/>
            <div class="message">
              <div>
                <small class="text-muted" v-if="p.status">{{ p.status.text }}</small>
                <small class="text-muted float-right mt-1">{{ p.time.split('+')[0].replace('T', ' ') }}</small>
              </div>
              <small class="text-muted">
                <i class="icon-location-pin"></i>&nbsp;&nbsp;{{ p.location.name }}
              </small>
              <div class="font-weight-bold">{{ p.description }}</div>
            </div>
          </div>
        </template>
      </div>
    </b-modal>

  </div>
</template>
