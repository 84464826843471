<script>
import PriceTable from '../../modal/PriceTable.vue';
import MarginTable from '../../modal/MarginTable.vue';

export default {
  name: 'OrderModalOrderTab',
  model: {prop: 'value', event: 'change'},
  props: ['value', 'setField', 'copyText'],
  components: {PriceTable, MarginTable},
  data() {
    return {
      busy: {
        orgMallInfo: false,
      },
      modal: {
        price_table: false,
        margin_table: false,
      },
      mallOrders: [],
    }
  },
  async created() {
    this.item.mall_info = this.item.mall_info || {};

    this.mallOrders = [];
    // if (this.item.mall_info && this.item.mall_info.goodsprice !== this.item.sales_price) {
    //   // 단일 주문건이 아니라면 활용할 수 없다
    //   this.item.mall_info = {};
    // }
    if (this.item.mall === '고도몰') { // 1570212271003
      this.$api.getJson(`/order/godoDetail?oid=${this.item.oid}`).then(j => {
        if (!j) return;
        this.mallOrders = j.list;
        // 전체 goodsprice 에서 쿠폰을 뺀 settleprice 를 goodsprice 대비 price 비중대로 나눈 뒤 개별 적립금을 뺀 것을 정산금액으로 잡는다.
        if (this.mallOrders.length && this.item.price_table) {
          let ord = this.mallOrders[0];
          let fee = this.$C.SETTLE_KIND_GODO[ord.settlekind].fee;
          let settle_price = ord.settleprice * (fee ? (100 - fee) : 100) / 100 - ord.reserve;
          this.item.mall_info.item_settle_price = settle_price * this.item.sales_price / ord.goodsprice;
          let item_margin = this.item.mall_info.item_settle_price - (this.item.price_table.supply.finalPrice - this.item.price_table.supply.pgFee) * this.item.qty;
          this.item.mall_info.item_margin = item_margin;
          this.item.mall_info.item_margin_rate = (item_margin > 0 ? item_margin / this.item.qty / this.item.price_table.finalPriceRoundUp : item_margin / this.item.qty / this.item.price_table.supply.finalPriceRoundUp) * 100;
        }
        if (j.list[0] && j.list[0].pccc && this.item.pccc !== j.list[0].pccc) {
          this.item.pccc = j.list[0].pccc;
          this.$emit('syncItemOrg', 'pccc');
          // this.item_org.pccc = j.list[0].pccc;
          this.$forceUpdate();
        }
      });
    } else if (this.item.mall === '스토어팜 (발란)') {
    }

    this.setEstimateDate().then();
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    async setEstimateDate() {
      const data = await (await fetch(`${this.MALL_URL}/api/estimate_date/delivery?`
        + `shopId=${this.item.shop_id}&orderReceiveDate=${this.item.order_receive_date}&goodsNo=${this.item.goodsno}`)).json();
      this.item.estimate_date_start = data.start;
      this.item.estimate_date_end = data.end;
      this.$forceUpdate();
    },
    async orgMallInfo() {
      this.busy.orgMallInfo = true;
      let j = await this.$api.getJson(`/order/orgMallInfo?oid=${this.item.oid}`);
      this.busy.orgMallInfo = false;
      if (j) {
        this.$modal.show({title:'원본주문입니다', type:'json', item:j.ord});
      }
    },

    async openAddressPopup() {
      if (this.busy.addressPopup) return;
      // goodsflow 연동 보류
      // goodsflow 연동 보류
      // if (type === 'return' && this.item._partner) {
      //   return alert('파트너센터에 가입된 파트너의 반품주소는 GoodsFlow 의 연동 때문에, 파트너센터에서 수정해주세요.');
      // }
      const $this = this;
      $this.busy.addressPopup = true;
      new daum.Postcode({
        oncomplete(data) {
          // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let extraRoadAddr = ''; // 참고 항목 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')';
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          $this.item.post_code = data.zonecode;
          $this.item.base_address = data.roadAddress + extraRoadAddr;
          if ($this.item.i18n) {
            $this.item.i18n.en.address = data.roadAddressEnglish;
          }

          $this.updateAddress();
        },
        onclose(data) {
          $this.busy.addressPopup = false;
        }
      }).open();
    },
    updateAddress() {
      this.item.address = this.item.base_address +
        (this.item.detail_address ? ' ' : '') + this.item.detail_address;
      this.$forceUpdate();
    },
    async renewPriceTable() {
      if (confirm('정말로 가격테이블을 갱신하시겠습니까? 가격테이블에 문제가 있는 경우만 진행해주세요.')) {
        let j = await this.$api.postJson('/order/renewPriceTable', {item: {oid: this.item.oid}});
        if (j) {
          if (j.ok === -1) {
            if (!confirm(j.msg + '\n강제로 가격테이블을 갱신하시겠습니까?')) return;
            j = await this.$api.postJson('/order/renewPriceTable', {item: {oid: this.item.oid}, force: true});
          }
          let item = this.items.list.filter(e=>e.oid === this.item.oid)[0];
          this.item.price_table = j.price_table;
          if (item) item.price_table = j.price_table;
          alert('갱신되었습니다');
        }
      }
    },
    async updateMarginTable() {
      if (!confirm('마진 테이블을 최신 상태로 갱신하시겠습니까?')) return;
      let j = await this.$api.postJson('/order/updateMarginTable', {oid: this.item.oid});
      if (j) {
        this.item.margin_table = j.margin_table;
        this.$utils.alert('갱신되었습니다.');
      }
    },
  }
}
</script>

<template>
  <div>
    <b-row>
      <b-col cols="8">
        <div class="title-sm">주문관련일시</div>
        <label class="col-form-label">
          <b-badge class="mx-1" variant="light">주문시각</b-badge>
          <span>{{item.order_dt || item.order_date}} ({{$utils.weekday(item.order_date)}})</span>
          <br/>
          <b-badge class="mx-1" variant="light">결제시각</b-badge>
          <span>{{item.payment_dt || item.payment_date}} ({{$utils.weekday(item.payment_date)}})</span>
          <br/>
          <b-badge class="mx-1" variant="light">주문접수</b-badge>
          <span>{{item.order_receive_dt || item.order_receive_date}} ({{$utils.weekday(item.order_receive_date)}})</span>
          <br/>
          <!--<span :style="item.order_dt ? 'font-weight:500' : ''" v-b-tooltip="item.order_dt || ''">{{item.order_receive_date}}({{utils.weekday(item.order_receive_date)}})</span>-->
          <b-badge class="mx-1" variant="dark">예상수령일</b-badge>
          <template v-if="!item.orderMade">
            <b>
              <span>{{item.estimate_date_start}}</span>
              ~ <span>{{item.estimate_date_end}}</span>
            </b>
            <small> (평균 {{item.deliveryDay}} 영업일)</small>&nbsp;
          </template>
          <small v-else>주문제작 상품의 배송일정은 상품 상세페이지에서 확인해주세요.</small>
        </label>
      </b-col>
      <b-col cols="4">
        <div class="title-sm">주문속성</div>
        <small>파트너 주문코드 : </small><b-badge variant="light">{{item.boutique_ordno}}</b-badge> <i class="fa fa-pencil" @click="setField('boutique_ordno')"></i>
        <b-badge v-if="item.boutique_ordno" class="ml-1 pointer" variant="light" @click="copyText(item.boutique_ordno)"><i class="fa fa-copy"></i></b-badge>
        <br/>
        <small>원가 : </small><b-badge variant="light">{{item.raw_price}}</b-badge> <i class="fa fa-pencil" @click="setField('raw_price')"></i>
        <b-badge v-if="item.raw_price" class="ml-1 pointer" variant="light" @click="copyText(item.raw_price)"><i class="fa fa-copy"></i></b-badge>
        <br/>
        <!--              <small>EC 코드 : </small><b-badge variant="light">{{item.ec_order_code}}</b-badge><br/>
                      <small>EC SUB 코드 : </small><b-badge variant="light">{{item.ec_order_sub_code}}</b-badge><br/>-->
        <div class="mt-2"></div>
        <template v-if="item.oneday_delivery">
          <span v-html="item._badgeOnedayDelivery"></span>
          <div v-if="item.tp_dt">
            <small>오늘도착 등록일: {{item.tp_dt}}</small><br/>
            <small>오늘도착 배송상태: {{item.tp_current}}</small><br/>
            <small>오늘도착 현위치: {{item.tp_where}}</small><br/>
            <small>오늘도착 배송예상시간: {{item.tp_estimate}}</small><br/>
          </div>
          <b-button v-if="item.tp_log" size="sm mt-2" @click="$modal.show({type:'json', item: item.tp_log})">오늘도착 취소이력 상세</b-button>
        </template>
        <span v-html="item._badgeTodayPick"></span>
        <span v-html="item._badgeOrderMade"></span>
        <template v-if="item.withdraw_flag">
          <span class="badge alert-warning" :title="item.withdraw_flag._dt">철회이력</span><br/>
        </template>
        <template v-if="item.ordershift">
          <span class="badge alert-danger">교체된 주문</span><br/>
        </template>
        <span v-if="item.gift_packing" class="mr-1 badge badge-danger">선물포장</span>
        <div v-if="item.store_pickup">
          <span v-if="item.store_pickup" class="badge badge-primary">매장픽업</span>
          {{item.storePickupDateStr}}
        </div>
        <div v-if="item.changeOrder">
          <span v-if="item.changeOrder.includes('REPAYMENT')" class="badge alert-danger mr-1">재결제 완료</span>
          <a v-if="item.changeOrder.includes('REPAYMENT')" :href="`${ADMIN_URL}/#/orders/list?ordno=`+item.repaymentOrderNo" target="_blank">{{item.repaymentOrderNo}}</a>
        </div>
        <div v-if="item.changeOrder">
          <span v-if="item.changeOrder.includes('OPTIONS')" class="badge alert-success mr-1">옵션 변경</span>
          <span v-if="item.changeOrder.includes('ADDRESS')" class="badge alert-success">배송지 변경</span>
        </div>
        <span v-if="item.orderType === 'purchase'" class="mr-1 badge badge-primary">매입계산서</span>
      </b-col>
    </b-row>

    <div class="title-sm">주문정보</div>
    <b-row>
      <b-col sm="4">
        <small>주문자</small>
        <b-form-input plaintext :value="item.orderer"></b-form-input>
      </b-col>
      <b-col sm="4">
        <small>주문자 전화번호</small><i class="fa fa-pencil" @click="setField('orderer_phone')"></i>
        <b-form-input plaintext :value="item.orderer_phone"></b-form-input>
      </b-col>
      <b-col sm="4">
        <small>주문자 휴대폰</small><i class="fa fa-pencil" @click="setField('orderer_mobile')"></i>
        <b-form-input plaintext :value="item.orderer_mobile"></b-form-input>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="4">
        <small for="receiver">수령자</small>
        <b-form-input id="receiver" v-model="item.receiver"></b-form-input>
      </b-col>
      <b-col sm="4">
        <small for="receiver_phone">수령자 전화번호</small>
        <b-form-input id="receiver_phone" v-model="item.receiver_phone"></b-form-input>
      </b-col>
      <b-col sm="4">
        <small for="receiver_mobile">수령자 휴대폰</small>
        <b-form-input id="receiver_mobile" v-model="item.receiver_mobile"></b-form-input>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <small for="address">수령자 주소</small> <i class="fa fa-pencil pointer" @click="openAddressPopup()"></i>
        <b-input-group>
          <b-input-group-prepend>
            <b-input class="bg-white" v-model="item.post_code" placeholder="우편번호" readonly></b-input>
          </b-input-group-prepend>
          <b-input class="bg-white" v-model="item.base_address" placeholder="기본주소" readonly></b-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <small for="detail_address">수령자 상세 주소</small>
        <b-form-input type="text" id="detail_address" v-model="item.detail_address" placeholder="상세 주소를 입력해주세요."></b-form-input>
      </b-col>
      <b-col>
        <small for="del_memo">배송기재사항</small>
        <b-form-input type="text" id="del_memo" v-model="item.del_memo"></b-form-input>
      </b-col>
    </b-row>
    <div v-if="item.mall === '고도몰' && item.mall_info.settlekind === 'v'">
      <div class="title-sm">환불 계좌정보
        <i class="fa fa-question-circle" v-b-tooltip="'발란몰 가상계좌 주문일 때 제공됩니다'"></i>
      </div>
      <b-row>
        <b-col sm="3">
          <small>은행명</small>
          <b-form-input type="text" readonly v-model="item.refund_bank_name"></b-form-input>
        </b-col>
        <b-col sm="3">
          <small>예금주</small>
          <b-form-input type="text" readonly v-model="item.refund_account_owner_name"></b-form-input>
        </b-col>
        <b-col sm="6">
          <small>계좌번호</small>
          <b-input-group>
            <b-form-input v-if="item.refund_account_number_mask" type="text" readonly v-model="item.refund_account_number_mask"></b-form-input>
            <b-form-input v-else type="text" readonly v-model="item.refund_account_number"></b-form-input>
            <b-input-group-append v-if="item.refund_account_number_mask">
              <b-button variant="secondary" size="sm" @click.prevent="() => {delete item.refund_account_number_mask; $forceUpdate()}">확인하기</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <div v-if="item.delivery_type === '해외' && item.i18n && item.i18n.en">
      <div class="title-sm">영문 주소정보
        <i class="fa fa-question-circle" v-b-tooltip="'해외배송일 때 제공됩니다'"></i>
      </div>
      <b-row>
        <b-col sm="3">
          <b-form-group>
            <small>Receiver</small>
            <b-form-input type="text" readonly v-model="item.i18n.en.receiver"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="9">
          <b-form-group>
            <small>Address</small>
            <b-form-input type="text" readonly v-model="item.i18n.en.address"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template v-if="$S.user.workType === 'balaaneer'">
      <div v-if="$R('ORDER_R') && item.price_table" class="mt-3 mb-2 pb-1 clearfix border-bottom"><b>가격 정보</b>

<!--        <div v-if="$R('ORDER_R') && item.price_table" class="mt-3 mb-2 pb-1 clearfix border-bottom"><b>원가정보</b>-->
<!--        <i class="fa fa-question-circle" v-b-tooltip="'상품 1개 기준입니다'"></i>-->
<!--        <i v-if="$R('ADMIN')" class="fa fa-refresh pull-right mt-2" v-b-tooltip="'가격테이블을 현재기준으로 갱신합니다'" @click="renewPriceTable"></i>-->
      </div>
<!--      <div v-if="$R('ORDER_R') && item.price_table && item.mall_info && item.mall === '고도몰'">-->
      <div v-if="$R('ORDER_R') && item.price_table && item.mall === '고도몰'">
        <b-row class="text-center">
          <b-col>
            <b-badge variant="light">소비자가</b-badge><br/>
            <span>{{$utils.comma(item.price_table.consumer.finalPrice)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">원본가</b-badge><br/>
            <span>{{$utils.comma(item.price_table.mappedPrice)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">가격고정가</b-badge><br/>
            <span>{{item.price_table.priceLimitRule ? $utils.comma(item.price_table.priceLimitRule.priceLimit) : 0}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">판매기준가</b-badge><br/>
            <span>{{$utils.rnc(item.price_table.standardPrice)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">발란회원가</b-badge><br/>
            <span>{{$utils.rnc(item.price_table.finalPriceRoundUp)}}</span>
          </b-col>
        </b-row>
<!--        <b-row class="text-center">-->
<!--          <b-col>-->
<!--            <b-badge variant="light">판매금액</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.goods_price)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">쿠폰금액</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.coupon_amount_divided)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">적립금사용액</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.emoney_divided)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">결제대상금액({{item.settlekind_name}})</b-badge><br/>-->
<!--            <span>{{$utils.rnc(item.discount_price)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">결제금액</b-badge><br/>-->
<!--            <span>{{$utils.rnc(item.payment_amount)}}</span>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--        <b-row class="text-center">-->
<!--          <b-col>-->
<!--            <b-badge variant="light">정산가(+적립금사용액)</b-badge><br/>-->
<!--            <span>{{$utils.rnc((item.settle_price) / item.qty)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">정산가(쿠폰무시)</b-badge><br/>-->
<!--            <span>{{$utils.rnc((item.settle_price + item.coupon_amount_divided) / item.qty)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">PG제외 원가</b-badge><br/>-->
<!--            <span :style="item.margin < 0 ? `color:red`:''">{{$utils.rnc(item.supply_pg_removed)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">마진({{$utils.round(item.margin_rate, 1)}}%)</b-badge><br/>-->
<!--            <span>{{$utils.rnc(item.margin / item.qty)}}</span>-->
<!--          </b-col>-->
<!--          &lt;!&ndash;<b-col>-->
<!--            <b-badge variant="light">마진율</b-badge><br/>-->
<!--            <span>{{$utils.round((item.sales_price - item.price_table.supply.finalPriceRoundUp)/item.sales_price*10000)/100}} %</span>-->
<!--          </b-col>&ndash;&gt;-->
<!--          <b-col>-->
<!--            <b-button size="sm mt-2" @click="$modal.show({type:'json', item:item.price_table})">상세보기</b-button>-->
<!--          </b-col>-->
<!--        </b-row>-->
      </div>
      <div v-else-if="$R('ORDER_R') && item.mall_info && item.mall === '스토어팜 (발란)'">
        <b-row class="text-center">
          <b-col>
            <b-badge variant="light">결제금액</b-badge><br/>
            <span>{{$utils.comma(item.mall_info.TotalPaymentAmount / item.qty)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">결제수수료<span>(-{{$utils.round(item.mall_info.PaymentCommission / item.mall_info.TotalPaymentAmount * 100, 2)}}%)</span></b-badge><br/>
            <span>{{$utils.rnc(item.mall_info.PaymentCommission / item.qty)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">지식쇼핑커미션<span>(-{{$utils.round(item.mall_info.KnowledgeShoppingSellingInterlockCommission / item.mall_info.TotalPaymentAmount * 100, 2)}}%)</span></b-badge><br/>
            <span>{{$utils.rnc(item.mall_info.KnowledgeShoppingSellingInterlockCommission / item.qty)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">정산금액<span>({{$utils.round(item.mall_info.ExpectedSettlementAmount / item.mall_info.TotalPaymentAmount * 100, 2)}}%)</span></b-badge><br/>
            <span>{{$utils.rnc(item.mall_info.ExpectedSettlementAmount / item.qty)}}</span>
          </b-col>
          <b-col>
            <b-button size="sm" @click="$modal.show({type:'json', item:item.mall_info})">상세보기</b-button>
          </b-col>
        </b-row>
      </div>
<!--      <div v-else-if="$R('ORDER_R') && item.price_table">-->
<!--        <b-row class="text-center">-->
<!--          <b-col>-->
<!--            <b-badge variant="light">결제금액</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.sales_price / item.qty)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">정산가<span v-if="item.mall_fee">(-{{item.mall_fee}}%)</span></b-badge><br/>-->
<!--            <span>{{$utils.rnc(item.settle_price / item.qty)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">PG제외 원가</b-badge><br/>-->
<!--            <span :style="item.settle_price / item.qty < item.supply_pg_removed ? `color:red`:''">{{$utils.rnc(item.supply_pg_removed)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">마진({{$utils.round(item.margin_rate, 1)}}%)</b-badge><br/>-->
<!--            <span>{{$utils.rnc(item.margin / item.qty)}}</span>-->
<!--          </b-col>-->
<!--          &lt;!&ndash;<b-col>-->
<!--            <b-badge variant="light">마진율</b-badge><br/>-->
<!--            <span>{{$utils.round((item.sales_price - item.price_table.supply.finalPriceRoundUp)/item.sales_price*10000)/100}} %</span>-->
<!--          </b-col>&ndash;&gt;-->
<!--          <b-col>-->
<!--            <b-button size="sm" @click="$modal.show({type:'json', item:item.price_table})">상세보기</b-button>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </div>-->

      <template v-if="$R('ORDER_R') && item.price_table && item.price_table.priceRebateRule">
        <div class="mt-3 mb-2 pb-1 clearfix border-bottom"><b>병행가격설정 정보</b> -
          {{item.price_table.priceRebateRule.page.no}}. {{item.price_table.priceRebateRule.page.name}}
          ({{item.price_table.priceRebateRule.page.st}} ~ {{item.price_table.priceRebateRule.page.ed}})
        </div>
        <b-row class="text-center">
          <b-col>
            <b-badge variant="light">final_sale_price</b-badge><br/>
            <span>{{$utils.comma(item.price_table.priceRebateRule.final_sale_price)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">price</b-badge><br/>
            <span>{{$utils.comma(item.price_table.priceRebateRule.price)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">consumer</b-badge><br/>
            <span>{{$utils.comma(item.price_table.priceRebateRule.consumer)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">옵션추가금</b-badge><br/>
            <span>{{$utils.comma(item.price_table.mappedOptionPrice)}}</span>
          </b-col>
          <b-col>
            &nbsp;
          </b-col>
        </b-row>
      </template>

      <div class="" v-if="$R('ORDER_R') && item.margin_table">
        <div class="title-sm">결제 정보</div>
        <b-row class="text-center">
          <b-col cols="3">
            <b-badge variant="light">발란몰 단위 판매 금액</b-badge><br/>
            <span>{{$utils.rnc(item.margin_table.mall.goodsPrice)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">수량</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.mall.qty)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">발란몰 판매금액</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.mall.salesPrice)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">쇼핑백 쿠폰 할인액</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.mall.coupon)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">최대혜택가</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.mall.memberPrice)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">적립금 사용액</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.mall.emoney)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">실결제금액</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.mall.orderSettlePrice)}}</span>
          </b-col>
          <b-col>
            &nbsp;
          </b-col>
        </b-row>
      </div>
      <div class="" v-else-if="$R('ORDER_R') && item.mall_info">
        <div class="title-sm">결제 정보</div>
        <b-row class="text-center">
          <b-col cols="3">
            <b-badge variant="light">발란몰 단위 판매 금액</b-badge><br/>
            <span>{{$utils.rnc(item.mall_info.goodsPrice)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">수량</b-badge><br/>
            <span>{{$utils.comma(item.qty)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">발란몰 판매금액</b-badge><br/>
            <span>{{$utils.comma(item.sales_price)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">쇼핑백 쿠폰 할인액</b-badge><br/>
            <span>{{$utils.comma(item.mall_info.coupon)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">최대혜택가</b-badge><br/>
            <span>{{$utils.comma(item.price_table.finalPrice)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">적립금 사용액</b-badge><br/>
            <span>{{$utils.comma(item.mall_info.emoney)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">실결제금액</b-badge><br/>
            <span>{{$utils.comma(item.mall_info.settleprice)}}</span>
          </b-col>
          <b-col>
            &nbsp;
          </b-col>
        </b-row>
      </div>
      <template v-if="$R('ORDER_R') && item.price_table && item.price_table.priceLimitRule">

        <div class="mt-3 mb-2 pb-1 clearfix border-bottom"><b>가격 고정 정보</b></div>
        <b-row class="text-center">
          <b-col>
            <b-badge variant="light">가격 고정 번호</b-badge><br/>
            <span>{{item.price_table.priceLimitRule.page.no}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">가격 고정 사유</b-badge><br/>
            <span>{{item.price_table.priceLimitRule.page.name}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">가격 고정 기간</b-badge><br/>
            <div>
              {{item.price_table.priceLimitRule.page.st}} ~ {{item.price_table.priceLimitRule.page.ed}}
            </div>
          </b-col>
          <b-col>
            <b-badge variant="light">가격 고정가</b-badge><br/>
            <span>{{$utils.comma(item.price_table.priceLimitRule.priceLimit)}}</span>
          </b-col>
          <b-col>
            &nbsp;
          </b-col>
        </b-row>
      </template>

      <template v-if="$R('ORDER_R') && item.price_table && item.price_table.promotion">
        <div class="mt-3 mb-2 pb-1 clearfix border-bottom"><b>프로모션 정보</b> -
          {{item.price_table.promotion.promo.no}}. {{item.price_table.promotion.promo.name}}
          ({{item.price_table.promotion.promo.st}} ~ {{item.price_table.promotion.promo.ed}})
        </div>
        <b-row class="text-center">
          <b-col>
            <b-badge variant="light">consumer</b-badge><br/>
            <span>{{$utils.comma(item.price_table.promotion.consumer)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">price</b-badge><br/>
            <span>{{$utils.comma(item.price_table.promotion.price)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">supply</b-badge><br/>
            <span>{{$utils.comma(item.price_table.promotion.supply)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">옵션추가금</b-badge><br/>
            <span>{{$utils.comma(item.price_table.cfOptPriceDiff.price)}}</span>
          </b-col>
          <b-col>
            &nbsp;
          </b-col>
        </b-row>
      </template>

      <div class="" v-if="$R('ORDER_R') && item.margin_table">
        <div class="title-sm">정산 정보</div>
        <b-row class="text-center">
          <b-col>
            <b-badge variant="light">판매기준가</b-badge><br/>
            <span>{{$utils.rnc(item.margin_table.standardPriceSale)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">1차 상시할인(파트너 분담)</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.partnerGrade ? item.margin_table.partnerGrade.discountBasePartner : 0)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">1차 상시할인(발란 분담)</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.partnerGrade ? item.margin_table.partnerGrade.discountBaseBalaan : 0)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">2차 비상시할인(파트너 분담)</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.partnerGrade ? item.margin_table.partnerGrade.discountCondPartner : 0)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">2차 비상시할인(발란 분담)</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.partnerGrade ? item.margin_table.partnerGrade.discountCondBalaan : 0)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">3차 발란 단독 할인</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.partnerGrade ? item.margin_table.partnerGrade.discountOnlyBalaan : 0)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">상품별 정액할인(파트너분담)</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.partnerGrade ? item.margin_table.partnerGrade.partnerAddAmount : 0)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">상품별 정액할인(발란분담)</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.partnerGrade ? item.margin_table.partnerGrade.balaanAddAmount : 0)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">쇼핑백 쿠폰 (발란 부담)</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.mall.coupon)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">최대혜택가</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.mall.memberPrice)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">수수료</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.fee)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">클레임비용</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.partnerClaimCost)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">당일배송 수수료</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.onedayCost)}}</span>
          </b-col>
          <b-col cols="3">
            <b-badge variant="light">정산 금액</b-badge><br/>
            <span>{{$utils.comma(item.margin_table.finalSettlePrice)}}</span>
          </b-col>
          <b-col>
            &nbsp;
          </b-col>
        </b-row>
<!--        <div class="title-sm">마진정보</div>-->
<!--        <b-row class="text-center">-->
<!--          <b-col>-->
<!--            <b-badge variant="light">판매가격</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.margin_table.finalPriceRounded)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">고객 결제가격</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.margin_table.mall.mallTakeAmount)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">파트너 정산액</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.margin_table.finalSettlePrice)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">최종 공헌이익</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.margin_table.finalTakeAmount)}}</span>-->
<!--          </b-col>-->
<!--          <b-col>-->
<!--            <b-badge variant="light">공헌이익률</b-badge><br/>-->
<!--            <span>{{$utils.comma(item.margin_table.finalTakeRate)}} %</span>-->
<!--          </b-col>-->
<!--        </b-row>-->
      </div>
      <div class="" v-if="false && item.mall === '고도몰' && mallOrders.length">
        <div class="title-sm">발란몰결제정보</div>
        <b-row class="text-center">
          <b-col>
            <b-badge variant="light">상품금액</b-badge><br/>
            <span>{{$utils.comma(mallOrders[0].goodsprice)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">쿠폰</b-badge><br/>
            <span>{{$utils.comma(mallOrders[0].coupon)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">이머니</b-badge><br/>
            <span>{{$utils.comma(mallOrders[0].emoney)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">결제금액</b-badge><br/>
            <span>{{$utils.comma(mallOrders[0].settleprice)}}</span>
          </b-col>
          <b-col>
            <b-badge variant="light">적립금</b-badge><br/>
            <span>{{$utils.comma(mallOrders[0].reserve)}}</span>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <b-badge variant="light">결제방식({{mallOrders[0].pg}})</b-badge><br/>
            <small>{{$C.SETTLE_KIND_GODO[mallOrders[0].settlekind].name}} <b-badge variant="danger" v-if="mallOrders[0].pgcancel === 'y'">취소됨</b-badge></small>
          </b-col>
          <!--<b-col>
            <b-badge variant="light">PG</b-badge><br/>
            <span>{{mallOrders[0].pg}}</span>
            <b-badge variant="danger" v-if="mallOrders[0].pgcancel === 'y'">취소됨</b-badge>
          </b-col>-->
          <b-col>
            <b-badge variant="light">주문상태</b-badge><br/>
            <span>{{{0:'주문접수', 1:'결제완료',2:'배송준비중',3:'배송중',4:'배송완료'}[mallOrders[0].step]}}</span>
          </b-col>
          <template v-if="$R('ORDER_R')">
            <b-col>
              <b-badge variant="light">상품결제금액</b-badge><br/>
              <span>{{$utils.rnc(item.sales_price)}}</span>
            </b-col>
            <b-col>
              <b-badge variant="light">상품정산금액</b-badge><br/>
              <span>{{$utils.rnc(item.mall_info.item_settle_price)}}</span>
            </b-col>
            <b-col>
              <b-badge variant="light">상품실마진({{$utils.round(item.mall_info.item_margin_rate, 1)}}%)</b-badge><br/>
              <span v-if="item.price_table">{{$utils.rnc(item.mall_info.item_margin)}}</span>
              <span v-else>-</span>
            </b-col>
          </template>
          <template v-else>
            <b-col></b-col>
            <b-col></b-col>
          </template>
          <!--<b-col>
            <template v-if="mallOrders[0].inflow">
              <b-badge variant="light">경유</b-badge><br/>
              <span>{{mallOrders[0].inflow}}</span>
            </template>
          </b-col>-->
        </b-row>
        <div v-for="(o,idx) in mallOrders" class="pt-2">
          <b-badge>상품 {{idx+1}}</b-badge>&nbsp;
          <b-badge variant="warning">{{o.maker}}</b-badge>&nbsp;
          <b-badge variant="primary">{{o.goodsno}}</b-badge>&nbsp;
          <b-badge variant="success">상품가격 {{o.price}}</b-badge>&nbsp;
          <b-badge variant="success">상품적립금 {{o.item_reserve}}</b-badge>&nbsp;
          <b-badge variant="secondary">{{o.opt1}}</b-badge>&nbsp;{{o.ea}} 개&nbsp;
          <br/>
          {{o.goodsnm}}
        </div>
      </div>
    </template>

    <template v-if="item.multiple > 1">
      <div class="title-sm">복수상품주문 정보</div>
      <a :href="`/#/deliveryBoard/${item.orderno}`" class="badge badge-light" target="_blank">{{item.orderno}}</a>
      주문번호로 {{item.multiple}} 개의 상품이 한 번에 주문되었습니다.
    </template>
    <template v-if="item.auto_order">
      <div class="title-sm">자동주문 정보</div>
      <div>
        {{item.auto_order._dt}} 에
        <b-badge v-if="item.auto_order.ok === 1" variant="success">성공</b-badge>
        <b-badge v-else variant="danger">실패</b-badge>
        하였습니다. <span v-if="item.auto_order.ok === 0">이유 : {{item.auto_order.msg}}</span>
      </div>
    </template>

    <div class="mt-4" v-if="$R('ORDER_R')">
      <b-button class="mr-1 mb-1" size="sm" variant="outline-dark" @click="orgMallInfo" :disabled="busy.orgMallInfo">
        <b-spinner class="mr-2" small v-if="busy.orgMallInfo"></b-spinner>
        발란몰 원본주문 보기
      </b-button>
      <b-button class="mr-1 mb-1" size="sm" variant="outline-dark" @click="modal.price_table = true">
        가격계산테이블 보기
      </b-button>
      <b-button v-if="$R('SHOP_W')" class="mr-1 mb-1" size="sm" variant="outline-dark" @click="modal.margin_table = true">
        마진 테이블 보기
      </b-button>
      <b-button v-if="$R('ADMIN') && !item.price_table" class="mr-1 mb-1" size="sm" variant="dark" v-b-tooltip="'가격테이블을 현재기준으로 갱신합니다'" @click="renewPriceTable">
        PriceTable 갱신
      </b-button>
    </div>

    <price-table v-if="item.price_table" v-model="item.price_table" :show.sync="modal.price_table"></price-table>
    <margin-table v-model="item.margin_table" :oid="item.oid" :show.sync="modal.margin_table" @updateMarginTable="updateMarginTable"></margin-table>
  </div>
</template>
