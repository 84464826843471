<template>
  <b-modal :title="title" size="lg" v-model="_show" :ok-only="okOnly" :ok-title="okTitle" :cancel-title="cancelTitle" @ok="ok" @cancel="cancel">
    <b-alert variant="danger" show>개발중인 화면입니다.</b-alert>
    <h5>
      가격 테이블(사전 공헌이익, 예상마진)
    </h5>
    <template v-if="pt.rebate_rate">
      <div class="summary mb-2">
        <b-row>
          <b-col>판매가 적용항목</b-col>
          <b-col style="font-weight: bold;background-color: #f2f2f2;">판매가</b-col>
          <b-col>정산가 적용항목</b-col>
          <b-col style="font-weight: bold;background-color: #f2f2f2;">정산가</b-col>
        </b-row>
        <b-row>
          <b-col>기본가격</b-col>
          <b-col>{{comma(pt.sale.basePrice)}}</b-col>
          <b-col>기본가격</b-col>
          <b-col>{{comma(pt.supply.basePrice)}}</b-col>
        </b-row>
        <b-row v-if="pt.sale.localRate || pt.supply.localRate">
          <b-col>할인할증({{pt.sale.localRate || 0}} %) 적용</b-col>
          <b-col>{{cnc(pt.sale.basePrice * (100 + +pt.sale.localRate) / 100)}}</b-col>
          <b-col>할인할증({{pt.supply.localRate || 0}} %) 적용</b-col>
          <b-col>{{cnc(pt.supply.basePrice * (100 + +pt.supply.localRate) / 100)}}</b-col>
        </b-row>
        <b-row v-if="pt.sale.localDivider || pt.supply.localDivider">
          <b-col>divider({{pt.sale.localDivider}}) 적용</b-col>
          <b-col>{{comma(pt.sale.localPrice)}}</b-col>
          <b-col>divider({{pt.supply.localDivider}}) 적용</b-col>
          <b-col>{{comma(pt.supply.localPrice)}}</b-col>
        </b-row>
        <b-row v-if="pt.sale.operateAdjustAmount || pt.supply.operateAdjustAmount">
          <b-col>운영 할인할증({{rnc(pt.sale.operateAdjustAmount / pt.sale.localPrice * 100, 2) || 0}} %) 적용</b-col>
          <b-col>{{comma(pt.sale.operateAdjustPrice)}}</b-col>
          <b-col>운영 할인할증({{rnc(pt.supply.operateAdjustAmount / pt.supply.localPrice * 100, 2) || 0}} %) 적용</b-col>
          <b-col>{{comma(pt.supply.operateAdjustPrice)}}</b-col>
        </b-row>
        <b-row v-if="pt.sale.partnerAdjustAmount || pt.supply.partnerAdjustAmount">
          <b-col>파트너 할인할증({{rnc(pt.sale.partnerAdjustAmount / pt.sale.localPrice * 100, 2) || 0}} %) 적용</b-col>
          <b-col>{{comma(pt.sale.partnerAdjustPrice)}}</b-col>
          <b-col>파트너 할인할증({{rnc(pt.supply.partnerAdjustAmount / pt.supply.localPrice * 100, 2) || 0}} %) 적용</b-col>
          <b-col>{{comma(pt.supply.partnerAdjustPrice)}}</b-col>
        </b-row>
        <b-row>
          <b-col>최종 판매가</b-col>
          <b-col class="bold">{{comma(pt.finalPriceRoundUp)}}</b-col>
          <b-col>정산 기준가</b-col>
          <b-col class="bold">{{comma(pt.supply.standardPrice)}}</b-col>
        </b-row>
        <b-row>
          <b-col>PG 수수료</b-col>
          <b-col>- {{rnc(pt.finalPriceRoundUp * pt.pgRate / 100)}}</b-col>
          <b-col>판매 수수료</b-col>
          <b-col>- {{comma(pt.rebate)}}</b-col>
        </b-row>
        <b-row>
          <b-col>수수료 제외 매출</b-col>
          <b-col>{{comma(pt.sale.finalPriceRoundUp - pt.finalPriceRoundUp * pt.pgRate / 100)}}</b-col>
          <b-col>최종 정산가</b-col>
          <b-col class="bold">{{comma(pt.supply.standardPrice - pt.rebate)}}</b-col>
        </b-row>
        <b-row>
          <b-col cols="3">마진</b-col>
          <b-col cols="9">
            {{rnc(pt.sale.finalPriceRoundUp - pt.finalPriceRoundUp * pt.pgRate / 100)}} -
            {{rnc(pt.supply.standardPrice - pt.rebate)}} =
            <b>{{rnc(pt.sale.finalPriceRoundUp - pt.finalPriceRoundUp * pt.pgRate / 100 - (pt.supply.standardPrice - pt.rebate))}}
              ({{rnc((pt.sale.finalPriceRoundUp - pt.finalPriceRoundUp * pt.pgRate / 100 - (pt.supply.standardPrice - pt.rebate)) / pt.sale.finalPriceRoundUp * 100, 2)}} %)</b>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.summary .row div {
  padding-top: 5px;
  padding-bottom: 5px;
}
.summary .row div:nth-child(1), .summary .row div:nth-child(3) {
  font-weight: bold;
  background-color: #f2f2f2;
}
.summary .row div:nth-child(2), .summary .row div:nth-child(4) {
  text-align: right;
}
</style>

<script>
export default {
  name: 'priceTableModal',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    title: {type: String, default: '가격계산테이블 확인'},
    okTitle: {type: String, default: '확인'},
    okOnly: {type: Boolean, default: true},
    cancelTitle: {type: String, default: '닫기'},
    show: {type: Boolean, default: false},
  },
  data() {
    return {
    }
  },
  computed: {
    pt: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    _show: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      }
    }
  },
  methods: {
    comma(...args) {
      return this.$utils.comma(...args);
    },
    cnc(...args) {
      return this.$utils.cnc(...args);
    },
    rnc(...args) {
      return this.$utils.rnc(...args);
    },
    ok() {
      this.$emit('ok');
    },
    cancel() {
      this.$emit('cancel');
    },
  }
}
</script>
