<template>
  <div class="pt-2">
    <perfect-scrollbar style="max-height:inherit;overflow-y:auto;overflow-x:hidden" class="pt-2">
      <c-table
          :table-data="items.list"
          :fields="fields.list"
          :perPage.sync="perPage"
          :isBusy="busy.list"
          :getMoreBusy="busy.listmore"
          :hasMore="hasMore.list"
          caption="<i class='fa fa-align-justify'>"
          @btn-clicked="btnAction"
          @get-more="list(true)"></c-table>
    </perfect-scrollbar>
  </div>
</template>


<script>
export default {
  name: 'OrderModalQnaTab',
  model: {prop: 'value', event: 'change'},
  props: ['value'],
  data() {
    return {
      form: {
        list: {
          limit: 100,
          skip: 0
        }
      },
      fields: {
        list: [
          {key: 'created_d', label: '문의 일자', class: 'text-center'},
          {key:'_actions3', label:'문의 제목', targets: [
              {html: '', event: 'show_details'}
            ]},
          {key: 'category_name', label: '문의 유형', class: 'text-center'},
          {key: 'html3', label: '답변 상태<br/>전시 상태', class: 'text-center'},
          {key: 'html2', label: '처리자<br/>최종 답변 일자', class: 'text-center'},
        ]
      },
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      perPage: 20,
    }
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    async btnAction(row, event) {
      if (event === 'show_detail') {

      }
    },
    async list(more) {
      const form = {...this.form.list};
      await this.$api.postTable(this, '/godo/qna/list', form, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      const statusHtmlMap = {
        'pending': '<div class="badge alert-danger">답변대기</div>',
        'checking': '<div class="badge alert-warning">확인중</div>',
        'complete': '<div class="badge alert-info">답변완료</div>'
      };
      e._actions3_html = `<div class="text-truncate pointer" style="width: 100px;"><b>${e.title}</b></div>`
      e.html3 = `${statusHtmlMap[e.status]}`
        + `<br/>${e.display_status ? '<div class="badge alert-success">정상</div>' : '<div class="badge alert-danger">삭제</div>'}`;
      e.html2 = `${e.answered_by || ''}<br/>${e.answered_d || ''}`;
      e._html = `<div role="group" class="input-group border-bottom border-white">`
        + `<div class="col-3  text-center bg-light form-control" style="line-height: 56px; height: 70px">문의</div>`
        + `<textarea class="form-control bg-white" disabled style="max-height: 70px; min-height: 70px" wrap="soft" placeholder="${e.question}"></textarea>`
        + `</div>`;
      if (e.answers && e.answers.length) {
        e.answers.forEach(answer => {
          e._html += `<div role="group" class="input-group"  style="border-bottom: 1px solid #E4E4E4; border-right: 1px solid #E4E4E4; border-radius: 3px;">`
            + `<div class="col-3 text-center bg-light form-control" style="line-height: 146px; height: 160px;">답변</div>`
            + `<div  class="col-9">`
            + `<div class="clearfix my-2">`
            + `<button type="button" disabled class="btn btn-dark btn-sm">처리자: ${answer.created_by}</button>`
            + `<span class="pull-right"><small>${answer.updated_at}</small></span>`
            + `</div>`
            + `<textarea class="form-control bg-white" disabled style="max-height: 100px; min-height: 100px" wrap="soft" placeholder="${answer.answer}"></textarea>`
            + `</div></div>`
        });
      }
    }
  },
  async created() {
    this.form.list.oid = this.item.oid;
    await this.list();
  }
}
</script>
