<script>

export default {
  name: 'OrderModalLogTab',
  // model: {prop: 'value', event: 'change'},
  props: ['value', 'statusCodeMap', 'handleDragover'],
  data() {
    return {
      busy: {
        psLog: false,
        opLog: false,
        mngLog: false,
      },
      befOrders: [],
    }
  },
  async created() {
    this.item.ps_log = (this.item.ps_log || []);
    this.item.op_log = (this.item.op_log || []);
    this.item.mng_log = (this.item.mng_log || []);
    if (this.item.orderer !== '***' && this.item.orderer_mobile.length >= 10) {
      this.$api.postJson('/order/befOrders', {oid: this.item.oid, name: this.item.orderer, mobile: this.item.orderer_mobile}).then(j => {
        if (!j) return;
        this.befOrders = j.list;
      });
    }

    this.$api.getJson(`/order/alimtalk?oid=${encodeURIComponent(this.item.oid)}`).then(j => {
      if (j) {
        this.item.bl_alimtalk = j.list;
        this.$forceUpdate();
        // console.log(row.bl_alimtalk);
      }
    });
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    async addLog(part) {
      let _log = part + '_log', _text = part + '_log_text';
      let content = this.$refs[_text].$el.value.trim();
      if (content) {
        let log = {content, _dt: this.$moment().format('YYYY-MM-DD HH:mm:ss'), _id:this.$S.user.id, _name:this.$S.user.name};
        this.item[_log].push(log);
        setTimeout(()=>{
          let container = this.$refs[_log];
          container.scrollTop = container.scrollHeight;
          this.$refs[_text].$el.value = '';
          this.$refs[_text].focus();
        });
        let j = await this.$api.postJson('/order/updateLog', {type:'add', part, item: {oid: this.item.oid, log: log}});
        if (!j) this.item[_log].pop();
        this.$emit('syncItemOrg', _log);
        this.$forceUpdate();
      }
    },
    async removeLog(part, log) {
      let _log = part + '_log';
      if (confirm('해당 Log를 삭제하시겠습니까?')) {
        log._del_dt = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        this.$forceUpdate();
        let j = await this.$api.postJson('/order/updateLog', {type:'remove', part, item: {oid: this.item.oid, log: log}});
        if (!j) {
          delete log._del_dt;
        }
        // this.item_org[_log] = this.item[_log];
        this.$emit('syncItemOrg', _log);
        this.$forceUpdate();
      }
    },
    async sendKakao() {
      /** 현재 상태에 맞는 알림톡을 발송한다 */
      let j = await this.$api.postJson('/order/sendKakao', {ordnos:[`${this.item.orderno}:${this.item.order_detailno}`]});
      if (j) {
        this.$utils.alert(j.msg || '알림톡 발송에 성공했습니다');
      }
    },
    async uploadFile(event) {
      let file = (event.dataTransfer || event.target).files[0];
      let typeEl = event.target;
      while (!typeEl.dataset.type && typeEl !== document.body) {
        typeEl = typeEl.parentElement;
      }

      const type = typeEl.dataset.type;
      if (!file) return this.$utils.alert('파일을 업로드해주세요');
      const uploadableExts = 'jpg,jpeg,png,bmp,webp'.split(',');
      if (!file.name.toLowerCase().split('.').pop().in(uploadableExts)) {
        return this.$utils.alert('jpg, jpeg, png, bmp, webp 파일을 업로드해주세요');
      }
      if (file.size > 50 * 1024 * 1024) { // 파일 용량 제한 50MB
        return this.$utils.alert('50MB 이하 파일만 업로드 가능합니다.');
      }
      // const formData = new FormData();
      // formData.append('file', file);
      // formData.append('oid', this.item.oid);
      // formData.append('type', type); // ps, op

      this.busy[`${type}Log`] = true;
      let j = await this.$api.postForm('/order/appendFile', {file, oid: this.item.oid, type});
      this.busy[`${type}Log`] = false;
      if (!j) return;

      const logField = `${type}_log`;
      this.item[logField].push(j.log);
      setTimeout(()=>{
        let container = this.$refs[logField];
        container.scrollTop = container.scrollHeight;
      });
      // this.item_org[logField] = this.item[logField];
      this.$emit('syncItemOrg', logField);
      this.$forceUpdate();
    },
  }
}
</script>

<template>
  <div>
    <label>PS Log</label>
    <i class="fa fa-question-circle ml-1" v-b-tooltip="'[권장 가이드] 용량 : 50MB 이하 / 등록 가능 확장자 : jpg, jpeg, png, bmp, webp'"></i>
    <div data-type="ps" @drop.prevent.stop="uploadFile" @dragover="handleDragover" @dragenter="handleDragover">
      <perfect-scrollbar ref="ps_log" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
        <div v-for="i in item.ps_log.slice().reverse()">
          <template v-if="!i._del_dt">
            <div class="message">
              <div>
                <small class="text-muted">{{i._name}}</small>
                <small class="text-muted float-right mt-1" @mouseover="_=>{i._show_del = true;$forceUpdate()}" @mouseleave="_=>{i._show_del = false;$forceUpdate()}">
                  <b-badge variant="danger"><i class="fa fa-close pointer" @click="removeLog('ps', i)" v-if="i._show_del"></i></b-badge><div class="d-inline-block ml-1">{{i._dt}}</div>
                </small>
              </div>
              <template v-if="i.type === 'file'">
                <a :href="i.url" target="_blank"><i class="fa fa-file-o"></i> {{i.name}}</a>
                <div></div>
              </template>
              <a v-else-if="i.type === 'image'" :href="i.url" target="_blank"><img :src="i.thumbs[100].url" style="max-width:100px; max-height:150px;"></a>
              <div v-else class="font-weight-bold" v-html="i.content.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
            </div>
            <hr>
          </template>
        </div>
      </perfect-scrollbar>
      <div v-if="!item.ps_log || item.ps_log.filter(e=>!e._del_dt).length === 0">
        <div class="mt-3 mb-3 text-center font-weight-bold">PS Log가 없습니다</div>
      </div>
      <b-textarea ref="ps_log_text" data-type="ps" @drop.prevent.stop="uploadFile" @dragover="handleDragover" @dragenter="handleDragover"></b-textarea>
      <input type="file" ref="ps_log_file" data-type="ps" style="display: none" @change="uploadFile" :disabled="busy.psLog">
      <div class="text-right mt-1">
        <b-button class="mr-1" size="sm" variant="light"><i class="fa fa-cloud-upload fs-16" @click="_=>{$refs.ps_log_file.value = null;$refs.ps_log_file.click()}"></i></b-button>
        <b-button class="" size="sm" variant="success" @click="addLog('ps')" :disabled="busy.psLog">저장<b-spinner class="ml-1" small v-if="busy.psLog"></b-spinner></b-button>
      </div>
    </div>
    <hr/>

    <label>운영 Log</label>
    <i class="fa fa-question-circle ml-1" v-b-tooltip="'[권장 가이드] 용량 : 50MB 이하 / 등록 가능 확장자 : jpg, jpeg, png, bmp, webp'"></i>
    <div data-type="op" @drop.prevent.stop="uploadFile" @dragover="handleDragover" @dragenter="handleDragover">
      <perfect-scrollbar ref="op_log" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
        <div v-for="i in item.op_log.slice().reverse()" v-if="!i._del_dt">
          <div class="message">
            <div>
              <small class="text-muted">{{i._name}}</small>
              <small class="text-muted float-right mt-1" @mouseover="_=>{i._show_del = true;$forceUpdate()}" @mouseleave="_=>{i._show_del = false;$forceUpdate()}">
                <b-badge variant="danger"><i class="fa fa-close pointer" @click="removeLog('op', i)" v-if="i._show_del"></i></b-badge><div class="d-inline-block ml-1">{{i._dt}}</div>
              </small>
            </div>
            <template v-if="i.type === 'file'">
              <a :href="i.url" target="_blank"><i class="fa fa-file-o"></i> {{i.name}}</a>
              <div></div>
            </template>
            <a v-else-if="i.type === 'image'" :href="i.url" target="_blank"><img :src="i.thumbs[100].url" style="max-width:100px; max-height:150px;"></a>
            <div v-else class="font-weight-bold" v-html="i.content.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
          </div>
          <hr>
        </div>
      </perfect-scrollbar>
      <div v-if="!item.op_log || item.op_log.filter(e=>!e._del_dt).length === 0">
        <div class="mt-3 mb-3 text-center font-weight-bold">운영 Log가 없습니다</div>
      </div>
      <b-textarea ref="op_log_text" data-type="op" @drop.prevent.stop="uploadFile" @dragover="handleDragover" @dragenter="handleDragover"></b-textarea>
      <input type="file" ref="op_log_file" data-type="op" style="display: none" @change="uploadFile" :disabled="busy.opLog">
      <div class="text-right mt-1">
        <b-button class="mr-1" size="sm" variant="light"><i class="fa fa-cloud-upload fs-16" @click="_=>{$refs.op_log_file.value = null;$refs.op_log_file.click()}"></i></b-button>
        <b-button class="" size="sm" variant="success" @click="addLog('op')" :disabled="busy.opLog">저장<b-spinner class="ml-1" small v-if="busy.opLog"></b-spinner></b-button>
      </div>
    </div>
    <hr/>

    <label>주문관리 Log</label>
    <i class="fa fa-question-circle ml-1" v-b-tooltip="'[권장 가이드] 용량 : 50MB 이하 / 등록 가능 확장자 : jpg, jpeg, png, bmp, webp'"></i>
    <div data-type="mng" @drop.prevent.stop="uploadFile" @dragover="handleDragover" @dragenter="handleDragover">
      <perfect-scrollbar ref="mng_log" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
        <div v-for="i in item.mng_log.slice().reverse()" v-if="!i._del_dt">
          <div class="message">
            <div>
              <small class="text-muted">{{i._name}}</small>
              <small class="text-muted float-right mt-1" @mouseover="_=>{i._show_del = true;$forceUpdate()}" @mouseleave="_=>{i._show_del = false;$forceUpdate()}">
                <b-badge variant="danger"><i class="fa fa-close pointer" @click="removeLog('mng', i)" v-if="i._show_del"></i></b-badge><div class="d-inline-block ml-1">{{i._dt}}</div>
              </small>
            </div>
            <template v-if="i.type === 'file'">
              <a :href="i.url" target="_blank"><i class="fa fa-file-o"></i> {{i.name}}</a>
              <div></div>
            </template>
            <a v-else-if="i.type === 'image'" :href="i.url" target="_blank"><img :src="i.thumbs[100].url" style="max-width:100px; max-height:150px;"></a>
            <div v-else class="font-weight-bold" v-html="i.content.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
          </div>
          <hr>
        </div>
      </perfect-scrollbar>
      <div v-if="!item.mng_log || item.mng_log.filter(e=>!e._del_dt).length === 0">
        <div class="mt-3 mb-3 text-center font-weight-bold">주문관리 Log가 없습니다</div>
      </div>
      <b-textarea ref="mng_log_text" data-type="mng" @drop.prevent.stop="uploadFile" @dragover="handleDragover" @dragenter="handleDragover"></b-textarea>
      <input type="file" ref="mng_log_file" data-type="mng" style="display: none" @change="uploadFile" :disabled="busy.mngLog">
      <div class="text-right mt-1">
        <b-button class="mr-1" size="sm" variant="light"><i class="fa fa-cloud-upload fs-16" @click="_=>{$refs.mng_log_file.value = null;$refs.mng_log_file.click()}"></i></b-button>
        <b-button class="" size="sm" variant="success" @click="addLog('mng')" :disabled="busy.mngLog">저장<b-spinner class="ml-1" small v-if="busy.mngLog"></b-spinner></b-button>
      </div>
    </div>
    <hr/>

    <label>PS 메모(구)</label>
    <b-textarea :value="item.ps_memo" @change="v => item.ps_memo = v" rows="4"></b-textarea>
    <!--<b-textarea v-model.lazy="item.ps_memo" rows="4"></b-textarea>-->

    <hr/>

    <div class="clearfix">
      <label>알림톡 이력</label>
      <b-button v-if="$R('ORDER_X')" class="pull-right" size="sm" variant="warning" @click="sendKakao">알림톡 발송</b-button>
    </div>
    <perfect-scrollbar style="max-height:400px;overflow-y:auto;overflow-x:hidden">
      <template v-if="item.bl_alimtalk && item.bl_alimtalk.length">
        <div v-for="(i, idx) in item.bl_alimtalk">
          <div class="message">
            <div class="float-right">
              <small class="text-muted">{{$utils.kstDT(i.sent_at || i.sent_at)}}</small>
            </div>
            <div class="font-weight-bold">{{(i.msg || i.content).split(']')[0]}}]</div>
            <small class="text-muted">{{(i.msg || i.content).split(']').slice(1).join(']').trim()}}</small>
          </div>
          <hr v-if="idx < item.bl_alimtalk.length - 1">
        </div>
      </template>
      <template v-else-if="item.api_store_kko_history && item.api_store_kko_history.length">
        <div v-for="(i, idx) in item.api_store_kko_history">  <!--v-b-popover.hover.right="i.msg"-->
          <div class="message">
            <div>
              <small class="text-muted">{{statusCodeMap[i.order_status_code].name}}</small>
              <small class="text-muted float-right mt-1">{{i.senddt}}</small>
            </div>
            <div class="font-weight-bold">{{i.msg.split(/\r?\n/)[1]}}</div>
            <small class="text-muted">{{i.msg.split(/\r?\n/).slice(2).join('\n')}}</small>
          </div>
          <hr v-if="idx < item.api_store_kko_history.length - 1">
        </div>
      </template>
      <div v-else>
        <div class="mt-3 mb-3 text-center font-weight-bold">알림톡 이력이 없습니다</div>
      </div>
    </perfect-scrollbar>
    <hr>

    <label v-if="item.kakao_noti_history">(구) 알림톡 이력</label>
    <perfect-scrollbar style="max-height:400px;overflow-y:auto;overflow-x:hidden">
      <div v-for="(i, idx) in item.kakao_noti_history">  <!--v-b-popover.hover.right="i.msg"-->
        <div class="message">
          <div>
            <small class="text-muted">{{statusCodeMap[i.order_status_code].name}}</small>
            <small class="text-muted float-right mt-1">{{i.senddt}}</small>
          </div>
          <div class="font-weight-bold">{{i.msg.split('\n')[1]}}</div>
          <small class="text-muted">{{i.msg.split('\n\n').slice(1).join('\n\n')}}</small>
        </div>
        <hr>
      </div>
    </perfect-scrollbar>

    <label>기존주문 이력 <small class="text-muted">(이름, 전화번호 단순매칭)</small></label>
    <perfect-scrollbar v-if="befOrders.length" style="max-height:600px;overflow-y:auto;overflow-x:hidden">
      <span v-for="ord in befOrders">  <!--v-b-popover.hover.right="i.msg"-->
        <div class="message">
          <div class="py-3 pb-5 mr-3 float-left" style="max-height:60px">
            <div class="avatar">
              <img :src="ord.img_urls ? ord.img_urls[0] : ''" class="img-avatar">
              <!--<b-badge variant="danger" class="avatar-status"></b-badge>-->
            </div>
          </div>
          <div>
            <a :href="`/#/deliveryBoard/${ord.orderno}/${ord.order_detailno}`" target="_blank"><small class="text-muted">[{{ord.mall}}] {{ord.orderno}}:{{ord.order_detailno}}</small></a><br/>
            <b-badge variant="primary">{{statusCodeMap[ord.order_status_code].name}}</b-badge>
            <!--<b-badge variant="secondary">{{ord.type === 'orderer' ? `주문자:${ord.orderer}` : `수령자:${ord.receiver}`}}</b-badge>-->
            <b-badge variant="light">{{ord.type === 'orderer' ? `주문자` : `수령자`}}</b-badge>
            <small class="text-muted ml-1 mt-1">(주문: {{ord.order_date}})</small>
            <small class="text-muted float-right mt-1">{{ord[ord.order_status_code+'_date']}}</small>
          </div>
          <div class="font-weight-bold text-truncate">{{ord.goodsnm}}</div>
          <div>
            <b-badge variant="light">{{ord.size}}</b-badge>&nbsp;
            <small>{{ord.qty}} 개</small>&nbsp;
            <b-badge variant="light">{{ord.sales_price}}</b-badge>
          </div>
        </div>
        <hr>
      </span>
    </perfect-scrollbar>
    <div v-else>
      <div class="my-3 text-center font-weight-bold">기존주문 이력이 없습니다</div>
    </div>
  </div>
</template>
