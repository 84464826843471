<template>
  <b-modal :title="title" size="xl" v-model="_show" :ok-only="okOnly" :ok-title="okTitle" :cancel-title="cancelTitle" @ok="ok" @cancel="cancel">
    <b-alert variant="danger" show>개발중인 화면입니다.</b-alert>
    <h5>
      마진 테이블
      <i v-if="$R('ADMIN')" class="fa fa-refresh pull-right mt-2" v-b-tooltip="'가격테이블을 현재기준으로 갱신합니다'" @click="updateMarginTable"></i>
    </h5>
    <htb v-model="htbRows" :config="htbConfig" height="calc(100vh - 300px)"></htb>
    <template v-if="htbRowsExtraSettle.length">
      <hr />
      <h5>
        기타정산(계: {{this.extraSettleSum.comma()}})
      </h5>
      <htb v-model="htbRowsExtraSettle" :config="htbConfigExtraSettle" height="300px"></htb>
    </template>
  </b-modal>
</template>

<style scoped>
</style>

<script>
import htb from '@/views/modules/HotTableBase.vue'

export default {
  name: 'marginTableModal',
  components: {htb},
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    oid: {type: String},
    title: {type: String, default: '마진 테이블 확인'},
    okTitle: {type: String, default: '확인'},
    okOnly: {type: Boolean, default: true},
    cancelTitle: {type: String, default: '닫기'},
    show: {type: Boolean, default: false},
  },
  data() {
    return {
      marginTableFields: [
        {field: 'ver', name: '버전'},

        {field: '상품 항목', name: ''},
        {field: 'saleType', name: '판매가 유형'},
        {field: 'exchange_rate', name: '환율'},
        {field: 'basePrice', name: '기본가격(Mapped)'},
        {field: 'limitedPrice', name: '가격고정가'},
        {field: 'localRateAmount', name: '상시할인할증금액'},
        {field: 'localPrice', name: '상시할인할증 적용 후 가격'},
        {field: 'operateAdjustAmount', name: '운영 할인할증 증감액'},
        {field: 'partnerAdjustAmount', name: '파트너 할인할증 증감액'},

        {field: 'Dynamic Pricing 항목', name: '', group: 'dynamicPricing'},
        {field: 'dynamicPricing.minPriceAll', name: '발란추천가', group: 'dynamicPricing'},
        {field: 'dynamicPricing.targetPrice', name: 'DP 목표가', group: 'dynamicPricing'},
        {field: 'dynamicPricing.dpMode', name: 'DP Mode', group: 'dynamicPricing'},
        {field: 'dynamicPricing.discountBaseBalaan', name: '1차 상시할인 발란 분담액', group: 'dynamicPricing'},
        {field: 'dynamicPricing.discountBasePartner', name: '1차 상시할인 파트너 분담액', group: 'dynamicPricing'},
        {field: 'dynamicPricing.discountCondBalaan', name: '2차 비상시할인 발란 분담액', group: 'dynamicPricing'},
        {field: 'dynamicPricing.discountCondPartner', name: '2차 비상시할인 파트너 분담액', group: 'dynamicPricing'},
        {field: 'dynamicPricing.discountOnlyBalaan', name: '3차 발란단독할인 부담액', group: 'dynamicPricing'},
        {field: 'dynamicPricing.discountRate', name: 'DP 할인율', group: 'dynamicPricing'},

        {field: 'adjustPrice', name: '운영할인할증 or DP 로 인해 변경된 판매가'},

        {field: '병행가격설정 항목', name: '', group: 'priceRebate'},
        {field: 'priceRebate.prFinalPrice', name: '병행가격설정 최종판매가', group: 'priceRebate'},
        {field: 'priceRebate.prPrice', name: '병행가격설정 정산판매가', group: 'priceRebate'},

        {field: '판매/정산 기준가', name: ''},
        {field: 'standardPriceSale', name: '판매기준가'},
        {field: 'standardPriceSupply', name: '정산기준가'},

        {field: '파트너 등급제 항목', name: '', group: 'partnerGrade'},
        {field: 'partnerGrade.dpMode', name: 'DP mode', group: 'partnerGrade'},
        {field: 'partnerGrade.minPriceAll', name: '발란추천가', group: 'partnerGrade'},
        {field: 'partnerGrade.targetPrice', name: 'DP 목표가', group: 'partnerGrade'},
        {field: 'partnerGrade.prePrice', name: 'DP 할인 전 가격', group: 'partnerGrade'},
        {field: 'partnerGrade.adjustPrice', name: 'DP 할인 후 가격', group: 'partnerGrade'},
        {field: 'partnerGrade.baseRate', name: '1차 상시할인율', group: 'partnerGrade'},
        {field: 'partnerGrade.condRate', name: '2차 비상시할인율', group: 'partnerGrade'},
        {field: 'partnerGrade.balaanRate', name: '3차 발란단독할인율', group: 'partnerGrade'},
        {field: 'partnerGrade.bRankClass', name: 'B Rank Class', group: 'partnerGrade'},
        {field: 'partnerGrade.ratioByBrank', name: 'B Rank Class 에 의한 분담비율', group: 'partnerGrade'},
        {field: 'partnerGrade.condRatio', name: '2차 비상시할인 발란 분담비율', group: 'partnerGrade'},
        {field: 'partnerGrade.discountBaseBalaan', name: '1차 상시할인 발란 분담액', group: 'partnerGrade'},
        {field: 'partnerGrade.discountBasePartner', name: '1차 상시할인 파트너 분담액', group: 'partnerGrade'},
        {field: 'partnerGrade.discountCondBalaan', name: '2차 비상시할인 발란 분담액', group: 'partnerGrade'},
        {field: 'partnerGrade.discountCondPartner', name: '2차 비상시할인 파트너 분담액', group: 'partnerGrade'},
        {field: 'partnerGrade.discountOnlyBalaan', name: '3차 발란단독할인 부담액', group: 'partnerGrade'},
        {field: 'partnerGrade.discountAmount', name: 'DP 할인액', group: 'partnerGrade'},
        {field: 'partnerGrade.discountRate', name: 'DP 할인율', group: 'partnerGrade'},
        {field: 'partnerGrade.totalDPAmount', name: 'DP 로 변화된 금액(할인액의 마이너스화)', group: 'partnerGrade'},
        {field: 'partnerGrade.balaanAddAmount', name: '파트너 등급제 발란 정액 할인액', group: 'partnerGrade'},
        {field: 'partnerGrade.partnerAddAmount', name: '파트너 등급제 파트너 정액 할인액', group: 'partnerGrade'},
        {field: 'partnerGrade.totalAddAdmount', name: '정액 할인액 총합', group: 'partnerGrade'},
        {field: 'partnerGrade.balaanAmountSum', name: '발란 할인액 총합', group: 'partnerGrade'},
        {field: 'partnerGrade.partnerAmountSum', name: '파트너 할인액 총합', group: 'partnerGrade'},
        {field: 'partnerGrade.totalAmount', name: '변화 금액 총합(할인액의 마이너스화)', group: 'partnerGrade'},

        {field: '최종 가격 및 수수료', name: ''},
        {field: 'feeRate', name: '수수료율'},
        {field: 'fee', name: '계산된 수수료'},
        {field: 'goodsSettlePriceSingle', name: '단일상품 정산대상금액'},
        {field: 'finalPriceRounded', name: '최종 판매가(최초 예상가, 회원대상)'},

        {field: '판매몰 항목', name: '', group: 'mall'},
        {field: 'mall.goodsPrice', name: '단일상품판매가격', group: 'mall'},
        {field: 'mall.qty', name: '주문수량', group: 'mall'},
        {field: 'mall.salesPrice', name: '수량상품판매가격', group: 'mall'},
        {field: 'mall.coupon', name: '현 주문 쿠폰 적용액', group: 'mall'},
        {field: 'mall.memberPrice', name: '현 주문 판매가격(= 최대혜택가 or 쿠폰 미적용가)', group: 'mall'},
        {field: 'mall.emoney', name: '현 주문 적립금 사용액', group: 'mall'},
        {field: 'mall.orderSettlePrice', name: '현 주문 결제대상금액', group: 'mall'},
        {field: 'mall.pg', name: 'PG사', group: 'mall'},
        {field: 'mall.settleKind', name: '결제수단', group: 'mall'},
        {field: 'mall.pgFeeType', name: 'PG 수수료 유형(% or 원)', group: 'mall'},
        {field: 'mall.pgFeeValue', name: 'PG 수수료율(or 고정값)', group: 'mall'},
        {field: 'mall.pgFee', name: '실 PG 수수료', group: 'mall'},
        {field: 'mall.channelFee', name: '채널수수료', group: 'mall'},
        {field: 'mall.emoneyReserve', name: '발생한 적립금', group: 'mall'},
        {field: 'mall.mallTakeAmount', name: '판매몰 수신금액(각종 비용 제외 후)', group: 'mall'},
        {field: 'mall.orderTakeAmount', name: '주문 직후 상품정산 마진', group: 'mall'},
        {field: 'mall.orderTakeRate', name: '주문 직후 상품정산 마진율', group: 'mall'},

        {field: '주문 이후 항목', name: ''},
        {field: 'goodsSettleFee', name: '계산된 수수료(외화), 수량적용'},
        {field: 'goodsSettlePrice', name: '정산대상금액(외화), 수수료 및 할인 적용, 수량적용'},
        // {field: 'goodsSettleDiscount', name: '파트너 할인액(수량적용)'}, // 의미없음
        {field: 'isClaimStatus', name: '클레임 상태인지(예상정산액 0원)'},
        {field: 'onedayCost', name: '당일배송 파트너 비용(반품시 0, 클레임비용에 포함)'},
        {field: 'claimCost', name: '고객 클레임비용 총합(+)'},
        {field: 'partnerClaimCost', name: '파트너 적용 클레임비용 총합(+)'},
        {field: 'etcCost', name: '기타 비용(평균자재비, 적립금 환불시 반품비 등)'},

        {field: '최종 공헌이익 및 정산금액', name: ''},
        {field: 'finalSettlePrice', name: '최종 정산액(한화)'},
        // {field: 'finalSettlePriceForex', name: '최종 정산액(외화, 파트너용)'},
        {field: 'finalTakeAmount', name: '최종 공헌이익'},
        {field: 'finalTakeRate', name: '최종 수익률(판매기준가 대비)'},
      ],
      dpFields: [
        {field: 'DP 보정 반영 항목', name: '', group: 'dynamicPricing'},
        {field: 'dpFee', name: 'DP 수수료 추가 징수'},
        {field: 'dpFeeQty', name: 'DP 수수료 추가 징수(수량적용)'},
        {field: 'dpSettlePrice', name: '최종 정산액'},
        {field: 'dpTakeAmount', name: '최종 공헌이익'},
        {field: 'dpTakeRate', name: '최종 수익률(판매기준가 대비)'},
      ],
      htbRows: [],
      htbRowsExtraSettle: [],
      htbConfig: {
        fields: [
          {key: 'field', name: '필드명', renderer: 'html'},
          {key: 'name', name: '내용'},
          {key: 'value', name: '값'},
        ],
      },
      htbConfigExtraSettle: {
        fields: [
          {key: 'idx', name: '순번'},
          {key: 'title', name: '타이틀'},
          {key: 'expected_settle_date', name: '정산예정일'},
          {key: 'completed_settle_date', name: '정산완료일'},
          {key: 'detail', name: '상세내용'},
          {key: 'amount', name: '금액'},
        ],
      },
      extraSettleSum: 0,
      extraSettleLoaded: false,
    }
  },
  created() {
    this.makeHtbRows();
    this.getExtraSettle();
  },
  watch: {
    mt: {
      deep: true,
      handler() {
        this.makeHtbRows();
      },
    },
    show(v) {
      if (v && !this.extraSettleLoaded) this.getExtraSettle();
    }
  },
  computed: {
    mt: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    _show: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      }
    }
  },
  methods: {
    makeHtbRows() {
      if (!this.mt) return;
      const rows = [];
      this.marginTableFields.forEach(f => {
        const {field, name, group} = f;
        if (group && !this.mt[group]) return;
        let value;
        if (field.includes('.')) {
          const [a, b] = field.split('.');
          value = this.mt[a] ? this.mt[a][b] : '';
        } else {
          value = this.mt[field] !== null ? this.mt[field] : '';
        }
        if (!name) { // title 일 경우
          rows.push({field: '', name: '', value: ''}); // 한줄 띄기
          rows.push({field: '<b class="text-success">' + field + '<b>', name, value});
        } else {
          rows.push({field, name, value});
        }
      });

      if (this.mt.dynamicPricing) {
        const dp = this.mt.dynamicPricing;
        const dpFee = dp.discountBasePartner + dp.discountCondPartner;
        const dpFeeQty = dpFee * this.mt.mall.qty;
        const dpObj = {
          dpFee,
          dpFeeQty,
          dpSettlePrice: this.mt.finalSettlePrice - dpFeeQty,
          dpTakeAmount: this.mt.finalTakeAmount + dpFeeQty,
          dpTakeRate: Math.round((this.mt.finalTakeAmount + dpFeeQty) / this.mt.standardPriceSale * 1000) / 10
        };
        this.dpFields.forEach(f => {
          const {field, name, group} = f;
          if (group && !this.mt[group]) return;
          const value = dpObj[field] !== null ? dpObj[field] : '';
          if (!name) { // title 일 경우
            rows.push({field: '', name: '', value: ''}); // 한줄 띄기
            rows.push({field: '<b class="text-success">' + field + '<b>', name, value});
          } else {
            rows.push({field, name, value});
          }
        });
      }

      this.htbRows = rows;
    },
    async updateMarginTable() {
      this.$emit('updateMarginTable');
    },
    async getExtraSettle() {
      const j = await this.$api.postJson('/order/getExtraSettle', {oid: this.oid});
      if (j) {
        let idx = 1;
        for (const row of j.rows) {
          const {title, expected_settle_date, completed_settle_date} = row;
          for (const item of row.items) {
            const {detail, amount} = item;
            this.htbRowsExtraSettle.push({idx, title, expected_settle_date, completed_settle_date, detail, amount});
          }
          idx++;
        }
        this.extraSettleLoaded = true;
      }
    },
    comma(...args) {
      return this.$utils.comma(...args);
    },
    cnc(...args) {
      return this.$utils.cnc(...args);
    },
    rnc(...args) {
      return this.$utils.rnc(...args);
    },
    ok() {
      this.$emit('ok');
    },
    cancel() {
      this.$emit('cancel');
    },
  }
}
</script>
